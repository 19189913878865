import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from '../../ApiBase';
import * as actions from '../../actions'

export function* getProductList() {
  const url = ApiBase.getUrl() + '/products/list';
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') },
    });
    yield put(actions.setProductList(response.data.data));
  } catch (error) {}
}