export const addRegulation = (data) => {
  return {
    type: "ADD_REGULATION",
    data
  }
}

export const searchRegulation = (params) => {
  return {
    type: "SEARCH_REGULATION",
    params
  }
}

export const setRegulation = (payload) => {
  return {
    type: "SET_REGULATION",
    payload
  }
}

export const getRegulationCentral = (id, params) => {
  return {
    type: "GET_REGULATION_CENTRAL",
    id, params
  }
}

export const setRegulationCentral = (payload) => {
  return {
    type: "SET_REGULATION_CENTRAL",
    payload
  }
}

export const getRegulationDetail = (id, params) => {
  return {
    type: "GET_REGULATION_DETAIL",
    id, params
  }
}

export const setRegulationDetail = (payload) => {
  return {
    type: "SET_REGULATION_DETAIL",
    payload
  }
}

export const bookmarkRegulation = (id) => {
  return {
    type: "BOOKMARK_REGULATION",
    id
  }
}

export const setRegulationResponse = (payload, actionType) => {
  return {
    type: "SET_REGULATION_RESPONSE",
    payload, actionType
  }
}


export const getRegulationList = (id, params) => {
  return {
    type: "GET_REGULATION_LIST",
    id, params
  }
}

export const setDataRegulationList = (data) => {
  return {
    type: "SET_REGULATION_LIST",
    data
  }
}

export const syncRegulation = (id, params) => {
  return {
    type: "SYNC_REGULATION",
    id, params
  }
}

export const uploadDocumentRegulation = (data, regId) => {
  return {
    type: "UPLOAD_DOCUMENT_REGULATION",
    data, regId
  }
}

export const deleteDocumentRegulation = (docId) => {
  return {
    type: "DELETE_DOCUMENT_REGULATION",
    docId
  }
}

export const downloadDocumentRegulation = (data, updateProgress) => {
  return {
    type: "DOWNLOAD_DOCUMENT_REGULATION",
    data,
    updateProgress,
  }
}

export const setRegulationDetailLoading = (payload) => {
  return {
    type: "SET_REGULATION_DETAIL_LOADING",
    payload
  }
}

export const downloadRegulationObligations = (data) => {
  return {
    type: "DOWNLOAD_REGULATION_OBLIGATIONS",
    data
  }
}

export const downloadRegulationCompanies = (data) => {
  return {
    type: "DOWNLOAD_REGULATION_COMPANIES",
    data
  }
}