import { updateState } from '../../utils/updateState.js';

const initialState = {
  obligationResponse: null,
  regulationResponse: null,
  documentResponse: null,
  complianceResponse: null,
};

const getBookmarkRegulationResponse = (state, action) => {
  return updateState(state, {
    regulationResponse: action.data
  })
}

const getBookmarkObligationResponse = (state, action) => {
  return updateState(state, {
    obligationResponse: action.data
  })
}

const getBookmarkDocumentResponse = (state, action) => {
  return updateState(state, {
    documentResponse: action.data
  })
}

const getBookmarkComplianceResponse = (state, action) => {
  return updateState(state, {
    complianceResponse: action.data
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BOOKMARK_REGULATION_RESPONSE': return getBookmarkRegulationResponse(state, action);
    case 'GET_BOOKMARK_OBLIGATION_RESPONSE': return getBookmarkObligationResponse(state, action);
    case 'GET_BOOKMARK_DOCUMENT_RESPONSE': return getBookmarkDocumentResponse(state, action);
    case 'GET_BOOKMARK_COMPLIANCE_RESPONSE': return getBookmarkComplianceResponse(state, action);
    default:
      return state;
  }
};

export default reducer;
