import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from '../../ApiBase';
import * as actions from '../../actions'

export function* requestLanguage(action) {
  const url = ApiBase.getUrl()+'/user-request/create';
  let data = action.data

  try{
    let response =  yield axios.post(url, {content: data},
      { 
        headers:{'access_token': localStorage.getItem("token")}
      }
    )
    
    yield put(actions.languageResponse(response.data, "request_language"))
  }catch(error){
    yield put(actions.languageResponse(error.response.data, "request_language"))
  }
}

export function* listLanguage() {
  const url = 'https://restcountries.eu/rest/v2/all';

  try {
    const response = yield axios.get(url)
    yield put(actions.listLanguageResponse(response.data))
  }
  catch(error) {
    yield put(actions.languageResponse(error.response.data))
  }
}
