import { put } from 'redux-saga/effects';
import axios from 'utils/axiosInterceptors';
import * as actions from '../actions';
import jwt from 'jsonwebtoken';
import ApiBase from '../ApiBase';

export function* loginSaga(action) {
  let url = `${ApiBase.getUrl()}/auth/login?lang=${action.params.lang}${ action.params.msalToken ? `&msalToken=${action.params.msalToken}` : `` }`;
  const data = {
    email: action.email,
    password: action.password,
    msalTokenFE: action.params.msalTokenFE,
    msalName: action.params.msalName,
    msalID: action.params.azcid,
    msalAuthority: action.params.azauth,
  };

  try {
    const response = yield axios.post(url, data);

    const resData = response.data.data;
    const { token } = resData;

    yield localStorage.setItem('token', token);
    yield put(actions.loginSuccess(token));
    yield put(actions.getUserData())
    yield put(actions.getUserProfile())
  } catch (error) {
    const attempts = error.response.headers['x-ratelimit-limit'] - error.response.headers['x-ratelimit-remaining']
    const errorData = error.response.data
    errorData.errors.attempts_message = errorData.errors.attempts_message.replace('$_attempts',attempts).replace('$_limit',error.response.headers['x-ratelimit-limit'])
    yield put(actions.loginFailed(errorData));
  }
}

export function* getUserDataSaga() {
  const token = yield localStorage.getItem('token');
  const { user_uuid, email, roles, iat, company_uuid, modules } = yield jwt.decode(token);

  yield put(actions.getUserDataSuccess(user_uuid, email, roles, iat, company_uuid, modules))
}

export function* checkAuthSaga(action) {

  if (action.token) {
    yield put(actions.getUserData())
    yield put(actions.loginSuccess(action.token));
  }
}

export function* logout() {
  const url = ApiBase.getUrl() + '/auth/logout'
  try {
    yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield localStorage.removeItem('token');
    yield localStorage.removeItem('email');
    yield localStorage.removeItem('modules');
    window.location.href = '/login'
  } catch (error) {
    console.log('error logout', error);
  }
}

export function* updateDataUser(action) {
  const url = ApiBase.getUrl() + '/users/update'
  let data = action.data
  try {
    let response = yield axios.put(url, data, { headers: { 'access_token': localStorage.getItem("token") } })
    yield put(actions.setUserResponse(response.data, 'update'));
    yield put(actions.setUserResponse(null));
  } catch (error) {
    yield put(actions.setUserResponse(error.response.data, 'update'));
    yield put(actions.setUserResponse(null));
  }
}

export function* getUserProfile() {
  const url = ApiBase.getUrl() + `/users/profile`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token'),
      },
    });
    const data = response.data.data;
    localStorage.setItem('modules', JSON.stringify(data.modules))
    let today = new Date()
    let todayISO = today.toISOString()
    if ((!data.roles || !data.modules.length) && data.company_data && data.company_data.expired_at < todayISO) {
      yield put(actions.setUserResponse({
        ...response.data,
        errors: {
          subscription: true
        }
      }, 'get'));
    }
    yield put(actions.setUserProfile(data));
  } catch (error) {
    if (error.response.data && !error.response.data.success) {
      if (error.response.data.errors && error.response.data.errors.subscription) {
        yield put(actions.setUserResponse(error.response.data, 'get'));
      } else {
        yield put(actions.logout())
        window.location.reload()
      }
    } else {
      console.log(error.response.data);
    }
  }
}

export function* getListRole() {
  const url = ApiBase.getUrl() + `/role/list`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token'),
      },
    });
    const data = response.data.data;
    yield put(actions.setListRole(data));
  } catch (error) { }
}

export function* getUserDetail(action) {
  const url = ApiBase.getUrl() + `/users/detail/${action.id}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token'),
      },
    });
    const data = response.data.data;
    yield put(actions.setUserDetail(data));
  } catch (error) { }
}

export function* updateUserById(action) {
  const url = ApiBase.getUrl() + `/users/update/${action.id}`
  let data = action.data
  try {
    let response = yield axios.put(url, data, { headers: { 'access_token': localStorage.getItem("token") } })
    yield put(actions.setUserResponse(response.data, 'updateById'));
    yield put(actions.setUserResponse(null));
  } catch (error) {
    yield put(actions.setUserResponse(error.response.data, 'updateById'));
    yield put(actions.setUserResponse(null));
  }
}

export function* getDetailRole(action) {
  const url = ApiBase.getUrl() + `/role/${action.id}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token'),
      },
    });
    const data = response.data.data;
    yield put(actions.setDetailRole(data));
  } catch (error) { }
}

export function* deleteUserById(action) {
  const url = ApiBase.getUrl() + `/users/delete/${action.id}`;
  yield put(actions.setDeletedUser(action.id));
  try {
    const response = yield axios.delete(url, {
      headers: {
        access_token: localStorage.getItem('token'),
      },
    });
    
    yield put(actions.setUserResponse(response.data, 'deleteById'));
    // yield put(actions.userGetUserList());
    yield put(actions.userGetUserGroupList());
  } catch (error) {
    yield put(actions.setUserResponse(error.response.data, 'deleteById'));
  }
}

export function* updateLicenseSubscription(action){
  const license = action.data.license;
  const id = action.data.company_uuid;
  const url = ApiBase.getUrl() + `/users/company/update-license/${id}`;

  try {
    const response = yield axios.put(url, {license: license},{
      headers: { access_token: localStorage.getItem('token') }
    })

    yield put(actions.setUserResponse(response.data, 'updateSubscription'));
  } catch (error) {
    yield put(actions.setUserResponse(error.response.data, 'updateSubscription'));
  }
}

export function* userCreatePassword(action) {
  const data = action.payload.params;
  const url = ApiBase.getUrl() + `/users/create-password`;
  try {
    yield axios.post(url, data, {
      headers: {
        access_token: action.payload.token,
      },
    });
    yield action.payload.onSuccess()
  } catch (err) {
    if (err.response) action.payload.onError(err.response.data.message)
  }
}

export function* checkInviteUserToken(action) {
  const url = ApiBase.getUrl() + `/users/check-invitation`;
  try {
    yield axios.get(url, {
      headers: {
        access_token: action.payload.token,
      },
    });
  } catch (err) {
    action.payload.onError()
  }
}

