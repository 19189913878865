import { updateState } from "../../utils/updateState.js";

const initialState = {
  customerList: null,
  customerDetail: null,
  customerActivity: null,
  response: null,
  custResponse: null,
  custResponseType: null,
  pageTotal: 1,
};

const customerGetCustomerListSuccess = (state, action) => {
  return updateState(state, { 
    customerList: action.customerList,
    pageTotal: action.pageTotal,
   });
};

const getCustomerDetail = (state, action) => {
  return updateState(state, { customerDetail: action.payload });
};

const getCustomerActivity = (state, action) => {
  return updateState(state, { customerActivity: action.payload });
};

const customerSetResponse = (state, action) => {
  return updateState(state, { response: action.response });
};

const customerDeleteResponse = (state) => {
  return updateState(state, { response: null });
};

const customerResponse = (state, action) => {
  return updateState(state, {
    custResponse: action.response,
    custResponseType: action.origin,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CUSTOMER_MANAGEMENT_GET_CUSTOMER_LIST_SUCCESS":
      return customerGetCustomerListSuccess(state, action);
    case "SET_CUSTOMER_DETAIL":
      return getCustomerDetail(state, action);
    case "SET_CUSTOMER_ACTIVITY":
      return getCustomerActivity(state, action);
    case "CUSTOMER_MANAGEMENT_SET_RESPONSE":
      return customerSetResponse(state, action);
    case "CUSTOMER_MANAGEMENT_DELETE_RESPONSE":
      return customerDeleteResponse(state);
    case "RESPONSE_CUSTOMER_MANAGEMENT":
      return customerResponse(state, action);
    default:
      return state;
  }
};

export default reducer;
