import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import * as actions from '../../actions'
import ApiBase from '../../ApiBase';

export function* createSector(action) {
  const url = ApiBase.getUrl()+'/sectors/create';
  const data = action.data
  try{
    const response = yield axios.post(
      url, data,
      {
        headers:{
          'access_token': localStorage.getItem("token")
        }
      })
    yield put(actions.sectorResponse(response.data))
  }catch(error){
    yield put(actions.sectorResponse(error.response.data))
  }
}

export function* updateSector(action) {
  const url = ApiBase.getUrl()+'/sectors/update';
  const data = action.data
  try{
    const response = yield axios.put(
      url, data,
      {
        headers:{
          'access_token': localStorage.getItem("token")
        }
      })
    yield put(actions.sectorResponse(response.data))
  }catch(error){
    yield put(actions.sectorResponse(error.response.data))
  }
}

export function* deleteSector(action) {
  const url = ApiBase.getUrl()+'/sectors/delete';
  const data = action.data
  try{
    const response = yield axios.delete(
      url,
      {
        headers:{
          'access_token': localStorage.getItem("token")
        },
        data

      })
    yield put(actions.setDeleteTopicResponse(response.data))
  }catch(error){
    yield put(actions.setDeleteTopicResponse(error.response.data))
  }
}
