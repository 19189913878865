export const restore = (data) => {
  return {
    type: "RESTORE_DATA",
    data
  }
}

export const setRestoreResponse = (payload) => {
  return {
    type: "SET_RESTORE_RESPONSE",
    payload
  }
}

export const getBackupList = (params) => {
  return {
    type: "GET_BACKUP_LIST",
    params
  }
}

export const setBackupList = (files) => {
  return {
    type: "SET_BACKUP_LIST",
    files
  }
}
