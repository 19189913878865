import { put, delay } from 'redux-saga/effects';
import axios from 'utils/axiosInterceptors';
import ApiBase from '../ApiBase';
import * as actions from '../actions';


export function* getSearchSuggestions(action) {
  const url = ApiBase.getUrl() + '/search/suggestions';
  const { payload: { q } } = action;
  try {
    const params = {
      q: q
    };

    yield put(actions.setSearchSuggestions({ data: [] }));
    yield delay(500);

    const { data: {data: { on_categories }} } = yield axios.get(url, {
      headers: { access_token:  localStorage.getItem('token') },
      params: params
    });

    yield put(actions.setSearchSuggestions({ data: on_categories }))
  } catch (error) {
    yield put(actions.setSearchSuggestions({ data: [] }))
  }
}

export function* getSearchResult(action) {
  const url = ApiBase.getUrl() + '/search';
  const { payload: { q, type, page_section_id } } = action;

  try {
    const params = {
      q,
      type,
      page_section_id
    };

    yield put(actions.setSearchIsLoading({ isLoading: true }));
    const { data: {  data } } = yield axios.get(url, {
      headers: { access_token:  localStorage.getItem('token') },
      params: params
    });
    yield put(actions.setSearchIsLoading({ isLoading: false }));

    if (type === 'sector') {
      yield put(actions.setSectorList(data));
      return
    }

    if (type === 'topics') {
      yield put(actions.setSectorDetail(data));
      return
    }

    if (type === 'regulations_hierarchy') {
      yield put(actions.setRegulationHierarchySuccess(data));
      return
    }

    if (type === 'regulations') {
      yield put(actions.setDataRegulationList(data));
      return
    }

    return null

  } catch (error) {
    console.error(error);
    yield put(actions.setSectorList([]));
    yield put(actions.setSearchIsLoading({ isLoading: false }));
  }
}

export function* getSearchElastic(action) {
  const { data } = action
  const url = ApiBase.getUrl() + `/search-filter`;
  try {
    let response = yield axios.post(url, data, {
      headers: {
        access_token: localStorage.getItem("token"),
      }
    });

    response = response.data.hits.hits
    const sanitizedResponse = response.map(queryResult => queryResult._source);
    yield put(actions.setSearchResult(sanitizedResponse, data.index))
  } catch { }
}