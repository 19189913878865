import { updateState } from '../../utils/updateState.js';

const initialState = {
  appVersionBE: '1.0.0',
}
 
const setAppVersion = (state, action) => {
  return updateState(state, {
    appVersionBE: action.payload.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VERSION_APP": return setAppVersion(state, action);
    default:
      return state;
  }
}

export default reducer;