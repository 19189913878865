import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from "../ApiBase";
import * as actions from "../actions";

export function* customerGetCustomerList(action) {
  const { page , params} = action
  const limit = 20
  const url = ApiBase.getUrl() + `/users/company/list?page=${page}&limit=${limit}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
      params
    });
    const { data } = response.data
    yield put(actions.customerGetCustomerListSuccess(data, data.total_page));
  } catch (error) {
    yield put(actions.customerSetResponse(error.response.data));
  }
}

export function* getCustomerDetail(action) {
  const companyId = action.companyId;
  const url = ApiBase.getUrl() + `/users/company/${companyId}`;

  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
    });
    yield put(actions.setCustomerDetail(response.data.data));
  } catch (error) {}
}

export function* customerCreateCustomer(action) {
  const url = ApiBase.getUrl() + "/users/company/create";
  const data = action.data;

  try {
    const response = yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });

    yield put(actions.customerGetCustomerList());
    yield put(actions.customerResponse(response.data, "create"));
  } catch (error) {
    yield put(actions.customerResponse(error.response.data, "create"));
  }
}

export function* customerUpdateCustomer(action) {
  const data = action.data;
  const companyId = data.company_uuid;
  const url = ApiBase.getUrl() + `/users/company/update/${companyId}`;

  try {
    const response = yield axios.put(url, data, {
      headers: {
        access_token: localStorage.getItem("token")
      },
    });
    yield put(actions.getCustomerDetail(companyId));
    yield put(actions.getCustomerActivity(companyId));
    yield put(actions.customerResponse(response.data, "update"));
  } catch (error) {
    if (error.response) {
      yield put(actions.customerResponse(error.response.data, "update"));
    } else {
      console.log('error', error)
    }
  }
}

export function* customerDeleteCustomer(action) {
  const companyId = action.companyId
  const url = ApiBase.getUrl() + `/users/company/delete/${companyId}`;

  try {
    const response = yield axios.delete(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
    })

    yield put(actions.customerResponse(response.data, "delete"));
  } catch (error) {
    yield put(actions.customerResponse(error.response.data, "delete"));
  }
}

export function* getCustomerActivity(action) {
  const id = action.id;
  const url = ApiBase.getUrl() + `/users/customer-activities/${id}`;

  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
    });
    yield put(actions.setCustomerActivity(response.data.data));
  } catch (error) {

  }
}

// export function* generateCsv(action){
//   const companyId = action.companyId;
//   const lang = localStorage.getItem('language');
//   const url = ApiBase.getUrl() + `/users/company/download-csv/${companyId}`

//   try {
//     const response = yield axios.get(url, {
//       headers: {
//         access_token: localStorage.getItem("token"),
//       },
//       params: {
//         lang: lang
//       },
//       responseType: 'arraybuffer',
//     });
    
//     const blob = new Blob([response.data], {type: response.data.extension});
    
//     const urlDown = window.URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = urlDown;
//     const contentDisposition = response.headers['content-disposition'];
//     let fileName = 'unknown';
//     if (contentDisposition) {
//       const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
//       if (fileNameMatch.length === 2){
//         fileName = fileNameMatch[1];
//       }
//     }
//     link.setAttribute('download', fileName);
//     document.body.appendChild(link);
//     link.click();

//     yield put(actions.getCustomerDetail(companyId));
//   } catch (error) {
//     console.log(error.response.data);
//   }
// }

export function* uploadCsv(action){
  const companyId = action.companyId
  const url = ApiBase.getUrl() + `/users/company/upload-archive/${companyId}`
  const file_csv = action.files

  const body = new FormData();
  body.append('csv-file', file_csv)

  try {
    const response = yield axios.post(url, body, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params: {
        lang: localStorage.getItem('language')
      }
    })
    yield put(actions.setUserResponse(response.data, 'upload_csv'));
  } catch (error) {
    const err = error.response && error.response.data ? error.response.data : error
    console.log(err);
    // yield put(actions.setUserResponse(err, 'upload_csv'));
  }
}

export function* updateCompanyProgress(action){
  const url = ApiBase.getUrl() + `/users/company/update-company-progress?page=${action.page?action.page:''}`
  try {
    const response = yield axios.get(url, {
      headers:{ 'access_token': localStorage.getItem("token") }
    })
    // identified progress page
    const progressKey = Array.isArray(action.page) ? `_${action.page[0]}` : action.page ? `_${action.page}` : ''
    yield put(actions.setUserResponse(response.data, `update_company_progress${progressKey}`));
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* uploadInitData(action){
  const url = ApiBase.getUrl() + `/users/company/insert-archive`
  const file_csv = action.files

  const body = new FormData();
  body.append('csv-file', file_csv)

  try {
    const response = yield axios.post(url, body, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params: {
        lang: localStorage.getItem('language')
      }
    })
    yield put(actions.setUserResponse(response.data, 'upload_csv'));
  } catch (error) {
    yield put(actions.setUserResponse(error.response.data, 'upload_csv'));
  }
}