import { updateState } from "../../utils/updateState.js";

const initialState = {
  userList: null,
  userParams: null,
  userGroupList: null,
  response: null,
  responseType: null,
  privilegeRoleList: [],
  privilegeModuleList: [],
  groupList: null,
  groupDetail: null,
  listUserCompany: null,
  searchResultUserCompany: null,
  searchResultUserCompanyFrom: null,
  divisionLabel: null,
  divisionLabelResponse: {},
  inviteNewUserSuccess: false,
  deletedUser: null,
  deletedUserGroup: null,
};

const getUserListSuccess = (state, action) => {
  return updateState(state, { userList: action.userList });
};

const setUserListParams = (state, action) => {
  return updateState(state, { userParams: action.params });
};

const getUserGroupListSuccess = (state, action) => {
  return updateState(state, { userGroupList: action.userList });
};

const userSetResponse = (state, action) => {
  return updateState(state,
    { response: action.response, responseType: action.responseType }
  );
};

const userDeleteResponse = (state) => {
  return updateState(state, { response: null });
};

const userSetRolePrivilege = (state, action) => {
  return updateState(state, { privilegeRoleList: action.privilegeRoleList });
};

const userAddRolePrivilegeResponse = (state, action) => {
  return updateState(state, { response: action.response });
};

const userGetModulePrivilegeResponse = (state, action) => {
  return updateState(state, {
    privilegeModuleList: action.privilegeModuleList,
  });
};

const userDeleteRolePrivilegeResponse = (state, action) => {
  return updateState(state, { response: action.response });
};

const userUpdateRolePrivilegeResponse = (state, action) => {
  return updateState(state, { response: action.response });
};

const setGroupUserList = (state, action) => {
  return updateState(state, { groupList: action.response });
};

const setGroupUserDetail = (state, action) => {
  return updateState(state, { groupDetail: action.payload });
};

const setGroupLabel = (state, action) => {
  return updateState(state, { divisionLabel: action.payload });
};

const setGroupLabelResponse = (state, action) => {
  return updateState(state, { divisionLabelResponse: action.payload });
};

const setListUserCompany = (state, action) => {
  return updateState(state, { listUserCompany: action.payload });
};

const setSearchUserCompany = (state, action) => {
  return updateState(state, { searchResultUserCompany: action.payload, searchResultUserCompanyFrom: action.from });
};

const setInviteNewUserSuccess = (state, action) => {
  return updateState(state, { inviteNewUserSuccess: action.payload });
};

const setDeletedUser = (state, action) => {
  return updateState(state, { deletedUser: action.payload });
};

const setDeletedUserGroup = (state, action) => {
  return updateState(state, { deletedUserGroup: action.payload });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_MANAGEMENT_SET_USER_LIST_PARAMS":
      return setUserListParams(state, action);
    case "USER_MANAGEMENT_GET_USER_LIST_SUCCESS":
      return getUserListSuccess(state, action);
    case "USER_MANAGEMENT_GET_USER_GROUP_LIST_SUCCESS":
      return getUserGroupListSuccess(state, action);
    case "USER_MANAGEMENT_SET_RESPONSE":
      return userSetResponse(state, action);
    case "USER_MANAGEMENT_DELETE_RESPONSE":
      return userDeleteResponse(state);
    case "USER_MANAGEMENT_SET_ROLE_PRIVILEGE":
      return userSetRolePrivilege(state, action);
    case "USER_MANAGEMENT_ADD_ROLE_PRIVILEGE_RESPONSE":
      return userAddRolePrivilegeResponse(state, action);
    case "USER_MANAGEMENT_GET_MODULE_PRIVILEGE_RESPONSE":
      return userGetModulePrivilegeResponse(state, action);
    case "USER_MANAGEMENT_DELETE_ROLE_PRIVILEGE_RESPONSE":
      return userDeleteRolePrivilegeResponse(state, action);
    case "USER_MANAGEMENT_UPDATE_ROLE_PRIVILEGE_RESPONSE":
      return userUpdateRolePrivilegeResponse(state, action);
    case "SET_GROUP_USER_LIST":
      return setGroupUserList(state, action);
    case "SET_GROUP_USER_DETAIL":
      return setGroupUserDetail(state, action);
    case "SET_LIST_USER_COMPANY":
      return setListUserCompany(state, action);
    case "SET_SEARCH_USER_COMPANY":
      return setSearchUserCompany(state, action);
    case "SET_GROUP_LABEL":
      return setGroupLabel(state, action);
    case "SET_GROUP_LABEL_RESPONSE":
      return setGroupLabelResponse(state, action);
    case "SET_INVITE_NEW_USER_SUCCESS":
      return setInviteNewUserSuccess(state, action);
    case "SET_DELETED_USER":
      return setDeletedUser(state, action);
    case "SET_DELETED_USER_GROUP":
      return setDeletedUserGroup(state, action);
    default:
      return state;
  }
};

export default reducer;
