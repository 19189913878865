import { updateState } from '../../utils/updateState.js';

const initialState = {
  loaderApp: false,
}
 
const setLoader = (state, action) => {
  return updateState(state, {
    loaderApp: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADER_APP": return setLoader(state, action);
    default:
      return state;
  }
}

export default reducer;