import {put} from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import * as actions from '../actions'
import ApiBase from '../ApiBase';

export function* getAlertList(action) {
  const url = ApiBase.getUrl()+'/alert-message/list';
  let params = action.params
  try{
    const response = yield axios.get(
      url,
      {
        headers:{
          'access_token': localStorage.getItem("token")
        },
        params
      })

    yield put(actions.setAlertList(response.data.data))
  }catch{

  }
}

export function* updateAlertList(action) {
  const url = ApiBase.getUrl()+'/alert-message/update';
  let data = action.data
  try{
    const response = yield axios.put(
      url, data,
      {
        headers:{
          'access_token': localStorage.getItem("token")
        }
      })

    yield put(actions.alertListResponse(response.data))
  }catch(error){
    yield put(actions.alertListResponse(error.response.data))
  }
}

export function* syncAlert() {
  const url = ApiBase.getUrl()+'/alert-message/sync';
  try{
    const response = yield axios.get(
      url,
      {
        headers:{
          'access_token': localStorage.getItem("token")
        }
      })
      yield put(actions.setSettingResponse(response.data, 'sync'))
  }catch{}
}


export function* downloadAlertTemplate(action) {
  const url = ApiBase.getUrl()+'/alert-message/template';
  let params = action.params
  try{
    const response = yield axios.get(
      url,
      {
        headers:{
          'access_token': localStorage.getItem("token")
        },
        params
      })
    yield put(actions.setAlertTemplate(response.data))
  }catch{}
}