export const setLoadingFeedbackForm = (payload) => {
    return {
        type: "SET_LOADING_FEEDBACK_FORM",
        payload,
    }
}

export const setSuccessFeedbackForm = (payload) => {
    return {
        type: 'SET_SUCCESS_FEEDBACK_FORM',
        payload
    };
};

export const setErrorMessageFeedbackForm = (payload) => {
    return {
        type: 'SET_ERROR_MESSAGE_FEEDBACK_FORM',
        payload
    };
};

export const sendFeedbackForm = (payload) => {
    return {
        type: 'SEND_FEEDBACK',
        payload
    };
};

export const setSidebarShown = (payload) => {
    return {
        type: 'SET_SIDEBAR_SHOWN',
        payload
    };
};
