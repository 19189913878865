export const getVersion = () => {
  return {
    type: "GET_VERSION_APP"
  }
}

export const setAppVersion = (payload) => {
  return {
    type: 'SET_VERSION_APP',
    payload
  };
};
