export const getFoldersList = (params) => {
  return { type: 'GET_FOLDERS_LIST', params }
}

export const getFoldersListResponse = (data) => {
  return { type: 'GET_FOLDERS_LIST_RESPONSE', data };
}

export const getChecklistList = (complianceId) => {
  return { type: 'GET_CHECKLIST_LIST', complianceId }
}

export const getChecklistResponse = (data) => {
  return { type: 'GET_CHECKLIST_RESPONSE', data }
}

export const getDocumentList = (checklistId, params = {}) => {
  return { type: 'GET_DOCUMENT_LIST', checklistId, params }
}

export const getDocumentResponse = (data) => {
  return { type: 'GET_DOCUMENT_RESPONSE', data }
}

export const bookmarkDocument = (documentId, checklistId) => {
  return {
    type: "BOOKMARK_DOCUMENT",
    documentId,
    checklistId
  }
}

export const uploadDocument = (data, complianceId, checklistId) => {
  return{
    type: 'UPLOAD_DOCUMENT',
    data,
    complianceId,
    checklistId
  }
}

export const updateDocument = (data) => {
  return { type: 'UPDATE_DOCUMENT', data }
}

export const downloadDocument = (data, checklistId, updateProgress) => {
  return{ type: 'DOWNLOAD_DOCUMENT', data, checklistId, updateProgress }
}

export const removeDocument = (data, checklistId) => {
  return { type: "REMOVE_DOCUMENT", data, checklistId }
}

export const getComplianceFolder = (checklistId, complianceId) => {
  return { type: "GET_COMPLIANCE_FOLDER", checklistId, complianceId }
}

export const getComplianceFolderResponse = (data) => {
  return { type: "GET_COMPLIANCE_FOLDER_RESPONSE", data }
}

export const getDocumentDetail = (documentId) => {
  return { type: "GET_DOCUMENT_DETAIL", documentId }
}

export const getDocumentDetailResponse = (data) => {
  return { type: "GET_DOCUMENT_DETAIL_RESPONSE", data }
}

export const setResponseDocument = (response, responseOrigin) => {
  return { type: "SET_RESPONSE_DOCUMENT", response, responseOrigin }
}

export const uploadDocumentResponse = (payload) => {
  return {
    type: "SET_UPLOAD_DOCUMENT_RESPONSE",
    payload
  }
}

export const relationDocument = (data) => {
  return {
    type: "RELATION_DOCUMENT",
    data
  }
}

export const relationRemove = (relationId) => {
  return {
    type: "RELATION_REMOVE",
    relationId
  }
}

export const searchDocumentRelation = (params) => {
  return {
    type: "SEARCH_DOCUMENT_RELATION",
    params
  }
}

export const setSearchDocumentRelation = (data) => {
  return {
    type: "SET_SEARCH_DOCUMENT_RELATION",
    data
  }
}