import {put} from 'redux-saga/effects';
import axios from 'utils/axiosInterceptors';
import ApiBase from '../../ApiBase';
import * as actions from '../../actions';

export function* getDataDashboard() {
  const url = ApiBase.getUrl() + '/dashboard/list';
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') },
    });
    yield put(actions.setDataDashboard(response.data.data));
  } catch (error) {}
}

export function* getDashboardComplianceStatus(credential) {
  const category = credential.category 
  const params = credential.params 
  if (!params.group) {
    delete params.group
  }
  switch (category) {
    case 'status-comply': yield put(actions.setDashboardComplianceStatusComplyLoading(true));
    case 'status-not-comply': yield put(actions.setDashboardComplianceStatusNotComplyLoading(true));
    case 'top-comply': yield put(actions.setDashboardComplianceTopComplyLoading(true));
    case 'top-not-comply': yield put(actions.setDashboardComplianceTopNotComplyLoading(true));
    case 'status-all': yield put(actions.setDashboardComplianceDivisionStatisticsLoading(true));
  }
  const url = ApiBase.getUrl() + '/dashboard/report/compliance-status';
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') },
      params
    });
    yield put(actions.setDashboardComplianceStatus(response.data.data, category));
  } catch (error) {
  } finally {
    switch (category) {
      case 'status-comply': yield put(actions.setDashboardComplianceStatusComplyLoading(false));
      case 'status-not-comply': yield put(actions.setDashboardComplianceStatusNotComplyLoading(false));
      case 'top-comply': yield put(actions.setDashboardComplianceTopComplyLoading(false));
      case 'top-not-comply': yield put(actions.setDashboardComplianceTopNotComplyLoading(false));
      case 'status-all': yield put(actions.setDashboardComplianceDivisionStatisticsLoading(false));
    }
  }
}

export function* getDashboardComplianceStatistics(credential) {
  yield put(actions.setDashboardComplianceStatisticsLoading(true));

  const params = credential.params 
  const url = ApiBase.getUrl() + '/dashboard/report/compliance-statistics';
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') },
      params
    });
    yield put(actions.setDashboardComplianceStatistics(response.data.data, credential.category));
  } catch (error) {
  } finally {
    yield put(actions.setDashboardComplianceStatisticsLoading(false));
  }
}

export function* getReportDashboard(action) {
  const params = action.params
  const url = ApiBase.getUrl() + '/dashboard/report/export';
  try {
    yield put(actions.setLoader(true))
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') },
      responseType: 'blob',
      params
    });

    const blob = yield new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    });

    const file = yield document.createElement("a");
    let dataFile = yield URL.createObjectURL(blob);
    file.setAttribute("href", dataFile);
    file.setAttribute("download",
      response.headers['content-disposition'] &&
      response.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
    );
    yield file.click()
    yield put(actions.setLoader(false))
  } catch (error) { 
    yield put(actions.setLoader(false)) 
  }
}


export function* getDashboardGroup(credential) {
  const params = credential.params 
  const url = ApiBase.getUrl() + '/dashboard/group';
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') },
      params
    });
    yield put(actions.setDashboardGroup(response.data?.data));
  } catch (error) {}
}


export function* searchDashboardGroup(action) {
  let params = action.params
  const url = ApiBase.getUrl() + `/dashboard/group-search`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
      params
    });
    yield put(actions.setSearchDashboardGroup(response.data.data));
  } catch { }
}