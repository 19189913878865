export const getBookmarkRegulation = () => {
  return { type: 'GET_BOOKMARK_REGULATION' };
};

export const getBookmarkObligation = () => {
  return { type: 'GET_BOOKMARK_OBLIGATION' };
};

export const getBookmarkDocument = () => {
  return { type: 'GET_BOOKMARK_DOCUMENT' };
};

export const getBookmarkCompliance = () => {
  return { type: 'GET_BOOKMARK_COMPLIANCE' };
};

export const getBookmarkRegulationResponse = (data) => {
  return { type: 'GET_BOOKMARK_REGULATION_RESPONSE', data };
};

export const getBookmarkObligationResponse = (data) => {
  return { type: 'GET_BOOKMARK_OBLIGATION_RESPONSE', data };
};

export const getBookmarkDocumentResponse = (data) => {
  return { type: 'GET_BOOKMARK_DOCUMENT_RESPONSE', data };
};

export const getBookmarkComplianceResponse = (data) => {
  return { type: 'GET_BOOKMARK_COMPLIANCE_RESPONSE', data };
};

export const deleteObligationBookmark = (obligationId) => {
  return { type: 'DELETE_BOOKMARK_OBLIGATION', obligationId };
};

export const deleteRegulationBookmark = (regulationId) => {
  return { type: 'DELETE_BOOKMARK_REGULATION', regulationId };
};

export const deleteDocumentBookmark = (documentId) => {
  return { type: 'DELETE_BOOKMARK_DOCUMENT', documentId };
};

export const deleteComplianceBookmark = (id) => {
  return { type: 'DELETE_BOOKMARK_COMPLIANCE', id };
};
