import {put} from 'redux-saga/effects';
import axios from 'utils/axiosInterceptors';
import * as actions from '../../actions';
import jwt from 'jsonwebtoken';
import ApiBase from '../../ApiBase';

export function* getObligationDetail(action) {
  const url = ApiBase.getUrl() + `/obligation/${action.id}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token'),
      },
    });
    const data = response.data.data; 
    yield put(actions.setObligationDetail(data));
    yield put(actions.setObligationResponse(null));
    yield put(actions.setObligationDetailLoading(false));
  } catch (error) { }
}

export function* getObligationList(action) {
  let params = action.params
  const url = ApiBase.getUrl() + `/obligation/list`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token')
      },
      params
    });
    const data = response.data.data; 
    yield put(actions.setObligationList(data));
  } catch (error) { }
}

export function* saveObligation(action) {
  // actionType is 'publish' or 'draft'
  const token = localStorage.getItem('token');
  const  {company_uuid} = jwt.decode(token)
  let url = '';
  if (action.data.has_notif) {
    action.data.has_notif = 0
  }
  let data = {...action.data, company_uuid: company_uuid};

  try {
    let response = null;

    if (action.actionType === 'publish') {
      delete data.status
      url = ApiBase.getUrl() + '/obligation/publish';
      response = yield axios.post(url, data, {
        headers: { access_token: localStorage.getItem('token') },
      });
    } else {
      url = ApiBase.getUrl() + '/obligation/update';
      response = yield axios.put(url, data, {
        headers: { access_token: localStorage.getItem('token') },
      });
    }
    if (action.data.obligation_status === -2) {
      yield put(actions.getObligationDetail(action.data.obligation_uuid));
    }
    yield put(actions.getSectorDetail(action.sectorId, {}));
    yield put(actions.setObligationResponse(response.data, action.actionType));
    // yield put(actions.setObligationResponse(null)s);
  } catch (error) {
    yield put(
      actions.setObligationResponse(error.response.data, action.actionType)
    );
    // yield put(actions.setObligationResponse(null));
  }
}

export function* getObligationType() {
  const url = ApiBase.getUrl() + `/obligation/category/list`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token'),
      },
    });
    const data = response.data.data;
    yield put(actions.setObligationType(data));
  } catch (error) { }
}

export function* bookmarkObligation(action) {
  const url = ApiBase.getUrl() + '/obligation/bookmark';
  try {
    yield axios.post(url, { obligation_uuid: action.id }, { headers: { 'access_token': localStorage.getItem("token") } })
    yield put(actions.getObligationDetail(action.id))
  } catch (error) {
    yield put(actions.setObligationResponse(error.response.data, 'bookmark'))
  }
}

export function* createObligation(action) {
  const url = ApiBase.getUrl() + '/obligation/create';
  const data = action.data
  try {
    yield axios.post(url, data, { headers: { 'access_token': localStorage.getItem("token") } })
    yield put(actions.getSectorDetail(action.sectorId, {}))
  } catch (error) {
    yield put(actions.regframeDetailResponse(error.response.data, 'create'))
  }
}

export function* getListUpdateObligation(action) {
  let params = action.params
  const url = `${ApiBase.getUrl()}/obligation/company/notification`;
  try {
    yield put(actions.setListUpdateObligation({loading: true}));
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token')
      },
      params
    });
    const data = response.data; 
    yield put(actions.setListUpdateObligation(data?.data));
  } catch (error) { }
}

export function* getListUpdateObligationByRegulation(action) {
  let params = action.params
  const url = `${ApiBase.getUrl()}/obligation/update/regulation`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token')
      },
      params
    });
    const data = response.data; 
    yield put(actions.setListUpdateObligationByRegulation(data?.data));
  } catch (error) { }
}

export function* getDetailUpdateObligation(action) {
  let params = action.params
  const url = `${ApiBase.getUrl()}/obligation/company/update/${params.id}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token')
      },
      params
    });
    const data = response.data;
    yield put(actions.setDetailUpdateObligation(data.data));
  } catch (error) {
   }
}

export function* applyObligationChanges(action) {
  const url = ApiBase.getUrl() + '/obligation/company/update';
  const data = action.data
  try {
    const response = yield axios.post(url, data, { 
      headers: { 
        'access_token': localStorage.getItem("token") 
      } 
    })
    yield put(actions.setObligationResponse(response.data, 'apply_obligation_changes'))
  } catch (error) {
    yield put(actions.setObligationResponse(error.response.data, 'apply_obligation_changes'))
  }
}

export function* getObligationUpdatingContentList(action) {
  const url = ApiBase.getUrl() + '/obligation/updating-content/date-list';
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token')
      },
    });
    const data = response.data;
    yield put(actions.setObligationUpdatingContentList(data.data));
  } catch (error) {
   }
}