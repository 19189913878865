import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import * as actions from "../../actions";
import ApiBase from "../../ApiBase";

export function* getPreferences(action) {
  const url = ApiBase.getUrl() + `/settings/detail/${action.companyId}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
    });
    
    yield put(actions.setPreferences(response.data.data));
  } catch (e) {
    yield put(actions.preferenceResponse(e));
  }
}

export function* updatePreferences(action) {
  const url = ApiBase.getUrl() + `/settings/update/${action.companyId}`;
  let data = action.data;

  try {
    const response = yield axios.put(url, data, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
    });

    yield put(actions.getPreferences(action.companyId));
    yield put(actions.preferenceResponse(response.data, "update"));
  } catch (e) {
    yield put(actions.preferenceResponse(e, "update"));
  }
}


export function* getLoginPreferences(action) {
  // const url = ApiBase.getUrl() + `/auth/login/${action.companyId}`;
  // try {
  //   const response = yield axios.get(url, {});

  //   yield put(actions.setPreferences(response.data.data));
  // } catch (e) {
  //   yield put(actions.preferenceResponse(e));
  // }
}