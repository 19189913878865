import {put} from 'redux-saga/effects';
import axios from 'utils/axiosInterceptors';
import * as actions from '../actions';
import ApiBase from '../ApiBase';

export function* getRegulationHierarchy(action) {
  let params = {...action.params, limit: 100}
  
  const url = ApiBase.getUrl() + `/regulations/hierarchy/list`;

  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem('token'),
      },
      params
    });

    const { data } = response.data;

    yield put(actions.setRegulationHierarchySuccess(data, data.page_total));
  } catch(error){
    yield put(actions.setRegulationHierarchyResponse(error.response.data))
  }
}

export function* addRegulationHierarchy(action){
  const data = action.data
  const url = ApiBase.getUrl() + `/regulations/create`

  try {
    const response = yield axios.post(url, data, {
      headers: {
        access_token: localStorage.getItem('token')
      }
    })

    yield put(actions.setResponseRegulation(response.data, "add_regulation"));
  } catch (error) {
    yield put(actions.setResponseRegulation(error.response.data, "add_regulation"));
  }
}

export function* updateRegulationHierarchy(action){
  const data = action.data

  const url = ApiBase.getUrl() + `/regulations/update`

  try {
    const response = yield axios.put(url, data, {
      headers: {
        access_token: localStorage.getItem('token')
      }
    })

    yield put(actions.setResponseRegulation(response.data, "update_regulation"));
  } catch (error) {
    yield put(actions.setResponseRegulation(error.response.data, "update_regulation"));
  }
}

export function* deleteRegulationHierarchy(action){
  const id = action.regId
  const data = {regulation_guid: id}

  const url = ApiBase.getUrl() + `/regulations/delete`

  try {
    const response = yield axios.delete(url,{
      headers: { access_token: localStorage.getItem('token') },
      data
    })

    yield put(actions.setResponseRegulation(response.data, "delete_regulation"));
  } catch (error) {
    yield put(actions.setResponseRegulation(error.response.data, "delete_regulation"));
  }
}

export function* syncRegulationHierarchy(action){
  const params = action.params
  const url = ApiBase.getUrl() + `/regulation/sync-on-export`
  
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') },
      params
    })

    yield put(actions.setResponseRegulation(response.data, "sync_regulations"));
  } catch (error) {
    console.log(error)
  }
}