import { call, put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import * as actions from "../actions";
import ApiBase from "../ApiBase";
import jwt from 'jsonwebtoken';

export function* publishDraftObligation(action) {
  let url;
  const data = { obligation_uuid: action.data, status: action.status, sector_uuid: action.sectorId, version_code: action.versionCode };

  try {
    let response;
    if (action.is_master) {
      url = ApiBase.getUrl() + "/v2/obligation/publish";
      response = yield axios.post(url, data, {
        headers: { access_token: localStorage.getItem("token") },
      });
    } else {
      url = ApiBase.getUrl() + "/obligation/update";
      response = yield axios.put(url, data, {
        headers: { access_token: localStorage.getItem("token") },
      });
    }

    yield put(actions.getSectorDetail(action.sectorId, {}));
    yield put(actions.getObligationDetail(action.data && action.data[0]));
    if (!action.is_master) yield put(actions.regframeDetailResponse(response.data, action.status));
  } catch (error) {
    // yield put(actions.regframeDetailResponse(error.response && error.response.data ? error.response.data : error, action.status));
  }
}

export function* deleteObligation(action) {
  const url = ApiBase.getUrl() + "/obligation/delete";
  const data = { topic_uuid: action.topic, obligation_uuid: action.obligation };

  try {
    const response = yield axios.delete(url, {
      headers: { access_token: localStorage.getItem("token") },
      data,
    });

    yield put(actions.getSectorDetail(action.sectorId, {}));
    yield put(actions.regframeDetailResponse(response.data, "delete"));
  } catch (error) {
    yield put(actions.regframeDetailResponse(error.response.data, "delete"));
  }
}

export function* deleteTopic(action) {
  const url = ApiBase.getUrl() + "/topics/delete";
  const data = { topic_uuid: action.id, customerId: action.customerId };
  try {
    const response = yield axios.delete(url, {
      headers: { access_token: localStorage.getItem("token") },
      data,
    });
    yield put(actions.regframeDetailResponse(response.data, "delete"));
    yield put(actions.getSectorDetail(action.sectorId, {}));
  } catch (error) {
    yield put(actions.regframeDetailResponse(error.response.data, "delete"));
  }
}

export function* createTopic(action) {
  const url = ApiBase.getUrl() + "/topics/create";
  let data = action.data;
  try {
    yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.getSectorDetail(action.data.sector_uuid, { customerId: data.customerId }));
  } catch (error) {
    yield put(actions.regframeDetailResponse(error.response.data, "create"));
  }
}

export function* updateTopic(action) {
  const url = ApiBase.getUrl() + "/topics/update";
  let data = action.data;
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.regframeDetailResponse(response.data, "update"));
    yield put(actions.getSectorDetail(action.data.sector_uuid, {}));
  } catch (error) {
    yield put(actions.regframeDetailResponse(error.response.data, "update"));
  }
}

export function* activateObligation(action) {

  const token = localStorage.getItem("token");

  if (action.data) {
    const url = ApiBase.getUrl() + "/v3/obligation/bulk-activation";

    const data = {is_active: 1, obligation_uuid: []};
    action.data.forEach((a) => {
      data.obligation_uuid.push(a.obligation_uuid);
      data.is_active = a.is_active;
    });

    try {
      yield axios.post(url, data, {
        headers: { access_token: localStorage.getItem("token") },
      });

      yield put(actions.getSectorDetail(action.sectorId));
      // yield put(actions.regframeDetailResponse(response.data, 'activate_obligation'));
    } catch (error) {
      // yield put(actions.regframeDetailResponse(error.response.data, 'activate_obligation'));
    }
  }
}

export function* getFilterObligation(action) {
  const { params } = action
  const url = ApiBase.getUrl() + `/obligation/sector/filter`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setFilterObligation(data));
  } catch (error) {
    console.log(error.response);
  }
}

export function* getFilterTopics(action) {
  const { params } = action
  const url = ApiBase.getUrl() + `/topics/filter/list`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setFilterTopics(data));
  } catch (error) {
    console.log(error.response);
  }
}

export function* downloadTemplate(action) {
  const { payload } = action;
  let url;
  if (payload.type === 'blank') {
    url = ApiBase.getUrl() + `/documents/uploads/regframe-template/` + payload.fileName;
  } else {
    url = ApiBase.getUrl() + `/documents/template/` + payload.fileName;
  }
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      responseType: 'blob',
    });
    const blob = yield new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    });

    const file = yield document.createElement("a");
    let dataFile = yield URL.createObjectURL(blob);
    file.setAttribute("href", dataFile);
    file.setAttribute("download",
        response.headers['content-disposition'] &&
        response.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
    );
    file.click()
  } catch (error) {
    console.log(error.response);
  }
}

export function* getSectorsMoveDestinations(action) {
  let url = `${ApiBase.getUrl()}/v2/sectors?orderBy=code&orderType=ASC`;
  const { payload } = action;
  if (payload != null) {
    url += `&companyUUID=${payload}`
  }
  try {
    yield put(actions.setLoadingMoveItemv3(true));
    yield put(actions.setErrorMessageMoveItemv3(''));
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
    });
    const data = response.data.data;
    yield put(actions.setMoveDestinations(data, 'sectors'));
  } catch (error) {
    console.log(error.response);
    if (error.response) yield put(actions.setErrorMessageMoveItemv3(error.response.data.message));
  } finally {
    yield put(actions.setLoadingMoveItemv3(false));
  }
}

export function* getTopicsMoveDestinations(action) {
  const params = action.payload;
  const url = `${ApiBase.getUrl()}/v2/topics`;
  try {
    yield put(actions.setLoadingMoveItemv3(true));
    yield put(actions.setErrorMessageMoveItemv3(''));
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params,
    });
    const data = response.data.data;
    yield put(actions.setMoveDestinations(data, 'topics'));
  } catch (error) {
    console.log(error.response);
    if (error.response) yield put(actions.setErrorMessageMoveItemv3(error.response.data.message));
  } finally {
    yield put(actions.setLoadingMoveItemv3(false));
  }
}

export function* moveItemv3Submit(action) {
  let data = action.payload;
  let url;
  if (data.customerDetail) {
    url = ApiBase.getUrl() + "/topics/update";
  } else {
    url = `${ApiBase.getUrl()}/move/regframe`;
  }
  try {
    yield put(actions.setLoading(true));
    yield put(actions.setErrorMessageMoveItemv3(''));
    yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.setShowMoveItemV3(false));
    action.onSuccess();
  } catch (error) {
    console.log(error.response);
    if (error.response) yield put(actions.setErrorMessageMoveItemv3(error.response.data.message));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* submitSortTopics(action) {
  let data = action.payload;
  const url = ApiBase.getUrl() + '/topic/sort'
  try {
    yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.getSectorDetail(action.sectorId, {}));
  } catch (error) {
    console.log(error.response);
  }
}

export function* submitSortObligations(action) {
  let data = action.payload;
  const url = ApiBase.getUrl() + '/obligation/sort'
  try {
    yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.getSectorDetail(action.sectorId, {}));
  } catch (error) {
    console.log(error.response);
  }
}

export function* initiateDownloadContentRegframe(action) {
  let data = action.data;
  const url = ApiBase.getUrl() + '/export/data-excel'
  try {
    yield axios.post(url, data, {
      headers: {
        access_token: localStorage.getItem("token")
      },
    });
  } catch (error) {
    console.log(error.response);
  }
}

export function* downloadObligationCompanies(action) {
  let data = action.data;
  const url = ApiBase.getUrl() + '/obligations/download-subscribed-companies'
  try {
    yield put(actions.setLoading(true));
    const response = yield axios.post(url, data, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      responseType: 'blob',
    });
    const blob = yield new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    });

    const file = yield document.createElement("a");
    let dataFile = yield URL.createObjectURL(blob);
    file.setAttribute("href", dataFile);
    file.setAttribute("download",
        response.headers['content-disposition'] &&
        response.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
    );
    file.click()
    yield put(actions.setLoading(false));
  } catch (error) {
    console.log(error.response);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* downloadObligationRegulations(action) {
  let data = action.data;
  const url = ApiBase.getUrl() + '/obligations/download-regulations'
  try {
    yield put(actions.setLoading(true));
    const response = yield axios.post(url, data, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      responseType: 'blob',
    });
    const blob = yield new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    });

    const file = yield document.createElement("a");
    let dataFile = yield URL.createObjectURL(blob);
    file.setAttribute("href", dataFile);
    file.setAttribute("download",
        response.headers['content-disposition'] &&
        response.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
    );
    file.click()
    yield put(actions.setLoading(false));
  } catch (error) {
    console.log(error.response);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* downloadContentRegframe(action) {
  const { payload } = action;
  const splitUrl = payload.split('/')
  const filename = splitUrl[splitUrl.length - 1]
  const url = ApiBase.getUrl() + `/documents/template/` + filename;

  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      responseType: 'blob',
    });
    const blob = yield new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    });

    const file = yield document.createElement("a");
    let dataFile = yield URL.createObjectURL(blob);
    file.setAttribute("href", dataFile);
    file.setAttribute("download", filename);
    file.click()
  } catch (error) {
    console.log(error.response);
  }
}