import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from '../ApiBase';
import * as actions from '../actions'

export function* getVersion(){
  const url = ApiBase.getUrl() + `/version`

  try {
    const response = yield axios.get(url, {
      headers:{ 'access_token': localStorage.getItem("token") }
    })
    yield put(actions.setAppVersion(response.data))
  } catch (error) {
    console.log(error.response.data);
    if ( error.response.data.message === "Unauthorized" ) {
      yield localStorage.removeItem('token');
      yield localStorage.removeItem('email');
      yield localStorage.removeItem('modules');
    }
  }
}