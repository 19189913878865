export const requestLanguage = (data) => {
  return {
    type: "REQUEST_LANGUAGE",
    data
  }
}

export const languageResponse = (response, responseOrigin) => {
  return {
    type: "RESPONSE_LANGUAGE",
    response,
    responseOrigin
  }
}

export const listLanguage = () => {
  return {
    type: "LIST_LANGUAGE",
  }
}

export const listLanguageResponse = (data) => {
  return {
    type: "LIST_LANGUAGE_RESPONSE",
    data
  }
}
