import { updateState } from '../../utils/updateState.js';

const initialState = {
  response: null,
  files: null
};

const getRestoreResponse = (state, action) => {
  return updateState(
    state,
    {
      response: action.payload
    }
  )
}

const getBackupList = (state, action) => {
  return updateState(
    state,
    {
      files: action.files
    }
  )
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RESTORE_RESPONSE': return getRestoreResponse(state, action);
    case 'SET_BACKUP_LIST': return getBackupList(state, action);
    default: return state;
  }
}

export default reducer;
