import {updateState} from "../../utils/updateState.js";

const initialState = {
  list: null,
  listTotalPage: null,
  alertListResponse: null,
  response: null,
  responseOrigin: null,
  languages: null,
  alertTemplate: null,
  settingResponse: null,
  settingResponseOrigin: null
}

const putAlertList = (state, action) => {
  return updateState(state, { 
    list: action.data.data || action.data,
    listTotalPage: action.data.total_page
  })
}

const putListLanguages = (state, action) => {
  return updateState(state, { languages: action.data })
}

const setRespAlert = (state, action) => {
  return updateState(state, {
    alertListResponse: action.response
  })
}

const setResponse = (state, action) => {
  return updateState(state, {
    response: action.response,
    responseOrigin: action.responseOrigin
  })
}

const setAlertTemplate = (state, action) => {
  return updateState(state, {
    alertTemplate: action.payload,
  })
}

const setSettingResponse = (state, action) => {
  return updateState(state, {
    settingResponse: action.payload,
    settingResponseOrigin: action.origin,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALERT_LIST": return putAlertList(state, action);
    case "ALERT_LIST_RESPONSE": return setRespAlert(state, action);
    case "RESPONSE_LANGUAGE": return setResponse(state, action);
    case "LIST_LANGUAGE_RESPONSE": return putListLanguages(state, action);
    case "SET_ALERT_TEMPLATE": return setAlertTemplate(state, action);
    case "SET_SETTING_RESPONSE": return setSettingResponse(state, action);
    default:
      return state;
  }
}

export default reducer;
