import { updateState } from "../../utils/updateState.js";

const initialState = {
  preferences: null,
  response: null,
  type: null
}

const setPreferences = (state, action) => {
  let data = Object.fromEntries(
    Object.entries(action.data).map(([key, value]) => [key, value === "null" ? null : value])
  );
  return updateState(state, {
     preferences: data, 
     type: null 
  })
}

const setResponse = (state, action) => {
  return updateState(state, {
    response: action.data,
    type: action.actionType
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PREFERENCES": return setPreferences(state, action);
    case "PREFERENCES_RESPONSE": return setResponse(state, action);
    default:
      return state;
  }
}

export default reducer;
