export const getPreferences = (companyId) => {
  return {
    type: "GET_PREFERENCES",
    companyId
  }
}

export const setPreferences = (data) => {
  return {
    type: "SET_PREFERENCES",
    data
  }
}

export const updatePreferences = (data, companyId) => {
  return {
    type: "UPDATE_PREFERENCES",
    data,
    companyId
  }
}

export const preferenceResponse = (data, actionType) => {
  return {
    type: "PREFERENCES_RESPONSE",
    data, actionType
  }
}

export const getLoginPreferences = (companyId) => {
  return {
    type: "GET_LOGIN_PREFERENCES",
    companyId
  }
}


export const setLoginPreferences = (payload) => {
  return {
    type: "SET_LOGIN_PREFERENCES",
    payload
  }
}
