import { updateState } from "../../utils/updateState.js";

const initialState = {
  q: "",
  type: "",
  isLoading: false,
  suggestions: [],
  searchResult: null,
  searchResultOrigin: null
};

const putQuery = (state, action) => {
  const { payload: { q } } = action;
  return updateState(state, { q: q })
};

const putSuggestions = (state, action) => {
  const { payload: { data } } = action;
  return updateState(state, {
    suggestions: data
  })
};

const putCategory = (state, action) => {
  const { payload: { category }} = action;
  return updateState(state, {
    type: category
  })
};

const putSearchLoading = (state, action) => {
  const { payload: { isLoading }} = action;
  return  updateState(state, {
    isLoading: isLoading
  })
};

const setSearchResult = (state, action) => {
  const { payload, origin } = action;
  return  updateState(state, {
    searchResult: payload,
    searchResultOrigin: origin
  })
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SEARCH_KEYWORD':
      return putQuery(state, action);
    case 'SET_SEARCH_SUGGESTIONS':
      return putSuggestions(state, action);
    case 'SET_SEARCH_CATEGORY':
      return putCategory(state, action);
    case 'SET_SEARCH_LOADING':
      return putSearchLoading(state, action);
    case 'SET_SEARCH_RESULT':
      return setSearchResult(state, action);
    default:
      return state;
  }
};

export default reducer;