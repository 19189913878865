import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from '../../ApiBase';
import * as actions from '../../actions'

export function* restore(action) {
  const url = ApiBase.getUrl() + `/database/restore/${action.data.id}`;

  try {
    const response = yield axios.post(
      url, {},
      {
        headers: {
          'access_token': localStorage.getItem("token")
        }
      }
    )
    //action
    yield put(actions.setRestoreResponse(response.data))
  } catch(error) {
    //response error
    yield put(actions.setRestoreResponse(error.response.data))
  }
}

export function* getBackupList(action) {
  const url = ApiBase.getUrl() + '/database/list';
  const params = action.params;

  try {
    const response = yield axios.get(
      url,
      {
        headers: {
          'access_token': localStorage.getItem("token")
        },
        params
      }
    )
    //action
    yield put(actions.setBackupList(response.data.data))
  } catch(error) {
    //response error
    console.log('ada error');
  }
}
