export const getLoader = () => {
  return {
    type: "GET_LOADER_APP"
  }
}

export const setLoader = (payload) => {
  return {
    type: 'SET_LOADER_APP',
    payload
  };
};
