import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import * as actions from '../../actions'
import ApiBase from '../../ApiBase';

export function* searchRegulation(action) {
  const url = ApiBase.getUrl()+'/regulations/search';
  let params = action.params
  try{
    const response = yield axios.get(
        url,
        {
          headers:{
            'access_token': localStorage.getItem("token")
          },
          params
        })
    const dataList = response.data.data
    yield put(actions.setRegulation(dataList))
  }catch (error){

  }
}

export function* addRegulation(action) {
  const url = ApiBase.getUrl()+'/obligation-regulation/create';
  const data = action.data
  try{
    yield axios.post(
        url, data,
        {
          headers:{
            'access_token': localStorage.getItem("token")
          }
        })
  }catch(error){
    yield put(actions.sectorResponse(error.response.data))
  }
}

export function* getRegulationCentral(action) {
  const url = ApiBase.getUrl()+`/regulations/central/${action.id}`;
  let params = action.params
  try{
    const response = yield axios.get(
        url,
        {
          headers:{
            'access_token': localStorage.getItem("token")
          },
          params
        })
    const data = response.data.data.data
    yield put(actions.setRegulationCentral(data))
  }catch (error){

  }
}

export function* getRegulationDetail(action) {
  yield put(actions.setRegulationDetail({}));
  const url = ApiBase.getUrl()+`/regulations/${action.id}`;
  let params = action.params
  try{
    const response = yield axios.get(
        url,
        {
          headers:{
            'access_token': localStorage.getItem("token")
          },
          params
        })
    const data = response.data.data
    yield put(actions.setRegulationDetail(data))
  }catch (error){
    yield put(actions.setRegulationResponse(error.response.data, 'get'))
  }
}

export function* bookmarkRegulation(action) {
  const url = ApiBase.getUrl()+'/regulations/bookmark';
  try{
    yield axios.post(url, {regulation_guid: action.id},{headers:{'access_token': localStorage.getItem("token")}})
    yield put(actions.getRegulationDetail(action.id))
  }catch(error){
    yield put(actions.setRegulationResponse(error.response.data, 'bookmark'))
  }
}

export function* getRegulationList(action) {
  yield put(actions.setDataRegulationList({}));
  const url = ApiBase.getUrl()+`/v2/regulations/hierarchy/${action.id}`;
  let params = action.params
  try{
    const response = yield axios.get(
        url,
        {
          headers:{
            'access_token': localStorage.getItem("token")
          },
          params
        })
    const data = response.data.data
    yield put(actions.setDataRegulationList(data))
  }catch (error){
    yield put(actions.setRegulationResponse(error.response.data, 'get'))
  }
}

export function* syncRegulaiton(action) {
  const url = ApiBase.getUrl()+`/regulations/sync/${action.id}`;
  let params = action.params
  try{
    const response = yield axios.get(
        url,
        {
          headers:{
            'access_token': localStorage.getItem("token")
          },
          params
        })
    const data = response.data
    yield put(actions.setRegulationResponse(data, 'sync'))
  }catch (error){
    yield put(actions.setRegulationResponse(error.response.data, 'sync'))
  }
}

export function* uploadDocumentRegulation(action){
  const id = action.regId;
  const data = action.data;
  const url = ApiBase.getUrl() + `/regulations/docs/upload`

  try {
    const response = yield axios.post(url, data,{
      headers: { access_token: localStorage.getItem('token') },
      params: {
        regulation_guid: id
      }
    })

    yield put(actions.setResponseRegulation(response.data, 'upload_document_regulation'))
  } catch (error) {
    yield put(actions.setResponseRegulation(error.response.data, 'upload_document_regulation'))
  }
}

export function* deleteDocumentRegulation(action){
  const url = ApiBase.getUrl() + `/regulations/docs/delete`
  const id = action.docId;
  const data = {
    "doc_guid": [id]
  }

  try {
    const response = yield axios.delete(url,{
      headers: { access_token: localStorage.getItem('token') },
      data
    })

    yield put(actions.setRegulationResponse(response.data, 'delete_document_regulation'))
  } catch (error) {
    yield put(actions.setRegulationResponse(error.response.data, 'delete_document_regulation'))
  }
}

export function* downloadDocumentRegulation(action){
  const data = action.data
  const url = ApiBase.getUrl() + `/regulation/document`;

  try {
    action.updateProgress({ progress: 0 })
    const response = yield axios.get(url,{
      headers: {
        'access_token': localStorage.getItem("token"),
      },
      params: data,
      responseType: 'arraybuffer',
      onDownloadProgress: (e) => {
        action.updateProgress({ progress: Math.round(e.loaded * 100 / e.total) })
      },
    })

    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urlBlob;
    link.setAttribute('download', data.alias_name + `.${data.extension}`); //or any other extension
    document.body.appendChild(link);
    link.click();
    action.updateProgress(null)
  } catch (error) {
    yield put(actions.setRegulationResponse(error.response.data, 'download_document'))
  }

}

export function* downloadRegulationCompanies(action) {
  let data = action.data;
  const url = ApiBase.getUrl() + '/regulations/download-subscribed-companies'
  try {
    yield put(actions.setRegulationDetailLoading(true));
    const response = yield axios.post(url, data, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      responseType: 'blob',
    });
    const blob = yield new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    });

    const file = yield document.createElement("a");
    let dataFile = yield URL.createObjectURL(blob);
    file.setAttribute("href", dataFile);
    file.setAttribute("download",
        response.headers['content-disposition'] &&
        response.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
    );
    file.click()
    yield put(actions.setRegulationDetailLoading(false));
  } catch (error) {
    console.log(error.response);
  } finally {
    yield put(actions.setRegulationDetailLoading(false));
  }
}

export function* downloadRegulationObligations(action) {
  let data = action.data;
  const url = ApiBase.getUrl() + '/regulations/download-obligations'
  try {
    yield put(actions.setRegulationDetailLoading(true));
    const response = yield axios.post(url, data, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      responseType: 'blob',
    });
    const blob = yield new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    });

    const file = yield document.createElement("a");
    let dataFile = yield URL.createObjectURL(blob);
    file.setAttribute("href", dataFile);
    file.setAttribute("download",
        response.headers['content-disposition'] &&
        response.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
    );
    file.click()
    yield put(actions.setRegulationDetailLoading(false));
  } catch (error) {
    console.log(error.response);
  } finally {
    yield put(actions.setRegulationDetailLoading(false));
  }
}