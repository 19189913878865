import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_en from './assets/translation/en.json';
import translation_id from './assets/translation/id.json';

const customLabelsEN = window._app_config.REACT_APP_CUSTOM_LABELS_EN && JSON.parse(window._app_config.REACT_APP_CUSTOM_LABELS_EN);
const customLabelsID = window._app_config.REACT_APP_CUSTOM_LABELS_ID && JSON.parse(window._app_config.REACT_APP_CUSTOM_LABELS_ID);

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {...translation_en,  ...customLabelsEN}
  },
  id: {
    translation: {...translation_id, ...customLabelsID}
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: ".", // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
