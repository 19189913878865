import {put} from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import i18n from '../../i18n';
import * as actions from '../actions'
import ApiBase from '../ApiBase';

export function* sendFeedback(action) {
    const url = `${ApiBase.getUrl()}/feedback`;
    const data = action.payload;

    try {
        yield put(actions.setLoadingFeedbackForm(true));
        yield put(actions.setErrorMessageFeedbackForm(null));
        yield axios.post(url, data, {
            headers: { access_token: localStorage.getItem("token") },
        });

        yield put(actions.setSuccessFeedbackForm(true))
    } catch (error) {
        yield put(actions.setSuccessFeedbackForm(false));
        if (error.response) {
            yield put(actions.setErrorMessageFeedbackForm(error.response.data.message));
        } else {
            yield put(actions.setErrorMessageFeedbackForm(i18n.t('feedbackForm.unknownErrorMessage')));
        }
    } finally {
        yield put(actions.setLoadingFeedbackForm(false));
    }
}