import axios from 'axios';    

axios.interceptors.request.use(
  function (config) {
    config.params = { ...config.params, t: Number(new Date()), lang: localStorage.getItem('language') } ;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response !== undefined) {
      const { status } = error.response;
      const token = localStorage.getItem('token');
      if (status === 401) {
        localStorage.removeItem('token');
        if (token != null) {
          window.location.href = ('/login')
        }
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
};
