import { updateState } from '../../utils/updateState';

const initialState = {
  foldersListResponse: null,
  checklistListResponse: null,
  documentListResponse: null,
  uploadResponse: null,
  complianceFolderResponse: null,
  folderNameResponse: null,
  documentResponse: null,
  documentResponseOrigin: null,
  documentDetailResponse: null,
  uploadDocumentResponse: null,
  documentRelation: null,
  searchDocumentRelation: null
};

const getFoldersResponse = (state, action) => {
  return updateState(state,
    {
      foldersListResponse: action.data
    }
  )
}

const getChecklistResponse = (state, action) => {
  return updateState(state,
    {
      checklistListResponse: action.data
    }
  )
}

const getDocumentResponse = (state, action) => {
  let filter = action.data && action.data.documents.filter(res => res)
  let data = {...action.data, documents: filter}
  return updateState(state,
    {
      documentListResponse: data
    }  
  )
}

const uploadResponse = (state, action) =>{
  return updateState(state,
    {
      uploadResponse: action.payload
    }
  )
}

const updateResponse = (state, action) => {
  return updateState(state,
    {
      updateResponse: action.payload
    }
  )
}

const downloadResponse = (state, action) => {
  return updateState(state,
    {
      downloadResponse: action.data
    }
  )
}

const complianceFolderResponse = (state, action) => {
  return updateState(state,
    {
      complianceFolderResponse: action.data
    }
  )
}

const folderNameResponse = (state, action) => {
  return updateState(state,
    {
      folderNameResponse: action.data
    }
  )
}

const documentDetailResponse = (state, action) => {
  return updateState(state,
    {
      documentDetailResponse: action.data
    } 
  )
}

const setResponseDocument = (state, action) => {
  return updateState(state,
    {
      documentResponse: action.response,
      documentResponseOrigin: action.responseOrigin,
    }
  )
}

const setUploadDocumentResponse = (state, action) => {
  return updateState(state,
    {
      uploadDocumentResponse: action.payload
    }  
  )
}

const setSearchDocumentRelation = (state, action) => {
  return updateState(state,
    {
      searchDocumentRelation: action.data.data
    }  
  )
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case 'GET_FOLDERS_LIST_RESPONSE': return getFoldersResponse(state, action);
    case 'GET_CHECKLIST_RESPONSE': return getChecklistResponse(state, action);
    case 'GET_DOCUMENT_RESPONSE': return getDocumentResponse(state, action);
    case 'UPLOAD_DOCUMENT_RESPONSE': return uploadResponse(state, action);
    case 'DOWNLOAD_DOCUMENT_RESPONSE': return downloadResponse(state, action);
    case 'UPDATE_DOCUMENT_RESPONSE': return updateResponse(state, action);
    case 'GET_COMPLIANCE_FOLDER_RESPONSE': return complianceFolderResponse(state, action);
    case 'GET_FOLDER_NAME_RESPONSE': return folderNameResponse(state, action);
    case 'SET_RESPONSE_DOCUMENT': return setResponseDocument(state, action);
    case 'GET_DOCUMENT_DETAIL_RESPONSE': return documentDetailResponse(state, action);
    case 'SET_UPLOAD_DOCUMENT_RESPONSE': return setUploadDocumentResponse(state, action);
    case 'SET_SEARCH_DOCUMENT_RELATION': return setSearchDocumentRelation(state, action);
    default: return state;
  }
}

export default reducer;