import { updateState } from "../../utils/updateState.js";

const initialState = {
  list: null,
  notificationHeader: null,
  counter: null,
  detailNotif: null
}

const putNotification = (state, action) => {
  return updateState(state, { list: action.data })
}

const putNotificationHeader = (state, action) => {
  return updateState(state, { notificationHeader: action.data })
}

const putCounterStaticNotification = (state, action) => {
  return updateState(state, { counter: action.data })
}

const putDetailNotification = (state, action) => {
  return updateState(state, { detailNotif: action.data })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NOTIFICATION": return putNotification(state, action);
    case "SET_NOTIFICATION_HEADER": return putNotificationHeader(state, action)
    case "SET_COUNTER_STATIC_NOTIFICATION": return putCounterStaticNotification(state, action)
    case "SET_DETAIL_NOTIFICATION": return putDetailNotification(state, action);
    default:
      return state;
  }
}

export default reducer;