import { put } from 'redux-saga/effects';
import axios from 'utils/axiosInterceptors';
import ApiBase from '../ApiBase';
import * as actions from '../actions';

export function* getFoldersList(action) {
  const url = ApiBase.getUrl() + '/folders/list?date=ASC';
  const params = action.params
  const topicId = { filter_topic_uuid: params.topicId }
  const pagination = {
    page: params.page,
    limit: params.limit
  }

  try {
    const response = yield axios.post(url, topicId,{
      headers: {access_token: localStorage.getItem('token')},
      params: pagination
    })

    yield put(actions.getFoldersListResponse(response.data.data));
  } catch (error){
    yield put(actions.getFoldersListResponse(error.response.data));
  }
}

export function* getChecklistList(action) {
  const complianceId = action.complianceId;

  const url = ApiBase.getUrl() + `/folders/list?compliance_uuid=${complianceId}&date=ASC`;

  try {
    const response = yield axios.post(url, '',{
      headers: {access_token: localStorage.getItem('token')}
    })

    yield put(actions.getChecklistResponse(response.data.data))
  }catch (error){
    yield put(actions.getChecklistResponse(error.response.data))
  }
}

export function* getDocumentList(action) {
  const checklistId = action.checklistId;
  let params = action.params
  if (checklistId) {
    params = {
      ...params,
      checklist_uuid : checklistId
    }
  }
  const url = ApiBase.getUrl() + `/documents/list`;

  try {
    const response = yield axios.get(url, {
      headers: {access_token: localStorage.getItem('token')},
      params
    })

    yield put(actions.getDocumentResponse(response.data.data))
  } catch(error){
    yield put(actions.getDocumentResponse(error.response.data))
  }
}

export function* bookmarkDocument(action) {
  const {documentId, checklistId} = action;
  const data = {
    document_uuid: documentId,
    checklist_uuid: checklistId
  }
  const url = ApiBase.getUrl() + `/documents/bookmark`;

  try {
    const response = yield axios.post(url, data, {
      headers: { 'access_token': localStorage.getItem("token") }
    })

    yield put(actions.setResponseDocument(response.data, "bookmark_document"));
  } catch(error){
    yield put(actions.setResponseDocument(error.response.data, "bookmark_document"));
  }
}

export function* uploadDocumentRequest(action){
  const data = action.data;
  const compId = action.complianceId;
  const checkId = action.checklistId;
  var parameter = {
    compliance_uuid: compId,
    checklist_uuid: checkId
  };

  const url = ApiBase.getUrl() + `/documents/upload`;

  try {
    let response = yield axios.post(url, data, {
      headers: {
        'access_token': localStorage.getItem("token")
      },
      params: parameter
    })

    yield put(actions.uploadDocumentResponse(response.data))
    if (checkId) {
      yield put(actions.setResponseCompliance(response.data, "upload_document"))
    } else {
      yield put(actions.setResponseDocument(response.data, "upload_document"));
    }
    yield put(actions.setResponseDocument(response.data, "upload_document"));
  } catch(error){
    if (checkId) {
      yield put(actions.setResponseCompliance(error.response.data, "upload_document"))
    } else {
      yield put(actions.setResponseDocument(error.response.data, "upload_document"));
    }
  }
}

export function* updateDocument(action){
  const documentId = action.data.document_uuid
  const url = ApiBase.getUrl() + `/documents/update/${documentId}`;
  const data = action.data

  try{
    const response = yield axios.put(url, data, {
      headers: {
        'access_token': localStorage.getItem("token")
      }
    })
    yield put(actions.setResponseCompliance(response.data, "update_document"));
    yield put(actions.setResponseDocument(response.data, "update_document"));
  }catch(err){
    yield put(actions.setResponseCompliance(err.response.data, "update_document"));
    yield put(actions.setResponseDocument(err.response.data, "update_document"));
  }
}

export function* downloadDocument(action){
  const data = action.data;
  const checklistId = action.checklistId;

  const url = ApiBase.getUrl() + `/documents/download/${data.document_uuid}`;

  try{
    action.updateProgress({ progress: 0 })
    const response = yield axios.get(url, {
      headers: {
        'access_token': localStorage.getItem("token"),
      },
      responseType: 'arraybuffer',
      onDownloadProgress: (e) => {
        action.updateProgress({ progress: Math.round(e.loaded * 100 / e.total) })
      },
    })

    const blob = new Blob([response.data], {type: response.data.extension});

    const urlDown = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlDown;
    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'unknown';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2){
        fileName = fileNameMatch[1];
      }
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    action.updateProgress(null)

    yield put(actions.getDocumentList(checklistId, {}))
  }catch(err){
    console.log('error', err);
  }
}

export function* removeDocument(action){
  const data = {
    document_uuids: action.data
  }

  const url = ApiBase.getUrl() + `/documents/delete?type=hard`;

  try{
    const response = yield axios.delete(url, {
      headers: { 'access_token': localStorage.getItem("token")},
      data
    })

    yield put(actions.setResponseCompliance(response.data, "delete_document_checklist"));
    yield put(actions.setResponseDocument(response.data, "remove_document"));
  }catch(err){
    yield put(actions.setResponseCompliance(err.response.data, "delete_document_checklist"));
    yield put(actions.setResponseDocument(err.response.data, "remove_document"))
  }
}

export function* getComplianceFolder(action){
  const compId  = action.complianceId
  const url = ApiBase.getUrl() + `/folders/list?compliance_uuid=${compId}`

  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') }
    })
    yield put(actions.getComplianceFolderResponse(response.data.data))
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* getDetailDocument(action){
  const id = action.documentId
  const url = ApiBase.getUrl() + `/documents/detail/${id}`

  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') }
    })

    yield put(actions.getDocumentDetailResponse(response.data.data))
  } catch (error) {
    yield put(actions.getDocumentDetailResponse(error.response.data))
  }
}

export function* relationDocument(action){
  const url = ApiBase.getUrl() + '/documents/map'
  const data = action.data

  try {
    const response = yield axios.post(url, data,{
      headers: { access_token: localStorage.getItem('token') }
    })

    yield put(actions.setResponseDocument(response.data, "relation_document"));
  }catch(err){
    yield put(actions.setResponseDocument(err.response.data, "relation_document"));
  }
}

export function* relationRemove(action){
  const id = action.relationId;
  const url = ApiBase.getUrl() + `/documents/map/${id}`

  try {
    const response = yield axios.delete(url, {
      headers: { access_token: localStorage.getItem('token') }
    })

    yield put(actions.setResponseDocument(response.data, "remove_relation_document"))
  } catch (error) {
    yield put(actions.setResponseDocument(error.response.data, "remove_relation_document"))
  }
}

export function* searchDocumentRelated(action){
  const params = action.params
  const url = ApiBase.getUrl() + `/documents/search-relation`

  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') },
      params: params
    })

    yield put(actions.setSearchDocumentRelation(response.data.data))
  } catch (error) {
    yield put(actions.setSearchDocumentRelation(error.response.data))
  }
}