import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from "../ApiBase";
import jwt from 'jsonwebtoken';
import * as actions from '../actions'

export function* userGetUserList(action) {
  let query = action.q
  let pages = action.page
  let sortBy = action.sortBy
  let sortValue = action.sortValue
  const url = ApiBase.getUrl() + "/users/company/list-user";
  try {
    let _params = {
      search: query,
      page: pages,
      sort_by: sortBy,
      sort_value: sortValue
    }
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
      params: _params
    });

    yield put(actions.userSetUserListParams(_params));
    yield put(actions.userGetUserListSuccess(response.data.data));
  } catch { }
}

export function* userGetUserGroupList(action) {
  let query = action.q
  let pages = action.page
  let sortBy = action.sortBy
  let sortValue = action.sortValue
  const url = ApiBase.getUrl() + "/users/company/group-user";
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
      params: {
        search: query,
        page: pages,
        sort_by: sortBy,
        sort_value: sortValue
      }
    });

    yield put(actions.userGetUserGroupListSuccess(response.data.data));
  } catch { }
}

export function* userCreateUser(action) {
  const url = ApiBase.getUrl() + "/users/create";
  const data = action.data;

  try {
    const response = yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });

    // yield put(actions.userGetUserList());
    yield put(actions.userGetUserGroupList());
    yield put(actions.userSetResponse(response.data));
  } catch (error) {
    yield put(actions.userSetResponse(error.response.data));
  }
}

export function* userGetRolePrivilege() {
  const url = ApiBase.getUrl() + "/role/list";

  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
    });

    yield put(actions.userSetRolePrivilege(response.data.data));
  } catch { }
}

export function* userAddRolePrivilege(action) {
  const url = ApiBase.getUrl() + "/role/create";
  const data = {
    role_name: action.name,
    role_setting: 0,
  };

  try {
    const response = yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.userAddRolePrivilegeResponse([response.data, "post"]));
  } catch (err) {
    yield put(actions.userAddRolePrivilegeResponse(err.response.data));
  }
}

export function* userGetModulePrivilege() {
  const url = ApiBase.getUrl() + "/module/list";

  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
    });

    yield put(actions.userGetModulePrivilegeResponse(response.data.data));
  } catch { }
}

export function* userDeleteRolePrivilege(action) {
  const url = ApiBase.getUrl() + `/role/delete?lang=${localStorage.getItem("language")}`;
  const data = {
    role_uuid: action.role_id,
  };

  try {
    const response = yield axios.delete(url, {
      headers: { access_token: localStorage.getItem("token") },
      data,
    });

    yield put(actions.userDeleteRolePrivilegeResponse([response.data, "delete"]));
  } catch (error) {
    yield put(actions.userDeleteRolePrivilegeResponse([error.response.data, "delete"]));
  }
}

export function* userUpdateRolePrivilege(action) {
  const url = ApiBase.getUrl() + "/role/privilege/update";
  let data = action.data;
  try {
    const response = yield axios.put(url, data, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
    });

    yield put(
      actions.userUpdateRolePrivilegeResponse([response.data, "update"])
    );
  } catch (error) {
    yield put(actions.userUpdateRolePrivilegeResponse(error));
  }
}

export function* getGroupUserList(action) {
  const url = ApiBase.getUrl() + "/v2/users/group/list";
  const query = action.q
  const pages = action.page
  const sortBy = action.sortBy
  const sortValue = action.sortValue
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
      params: {
        search: query,
        page: pages,
        sort_by: sortBy,
        sort_value: sortValue
      }
    });
    yield put(actions.setGroupUserList(response.data.data));
  } catch { }
}


export function* createGroupUser(action) {
  const url = ApiBase.getUrl() + "/v2/users/group/create";
  const data = action.data;

  try {
    const response = yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.getGroupUserList());
    yield put(actions.userSetResponse(response.data, "create-group"));
  } catch (error) {
    yield put(actions.userSetResponse(error.response.data, "create-group"));
  }
}


export function* deleteGroupUser(action) {
  const groupId = action.id;
  const url = ApiBase.getUrl() + `/users/group/delete/${groupId}`;

  yield put(actions.setDeletedUserGroup(action.id));
  try {
    const response = yield axios.put(url, {}, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.getGroupUserList());
    yield put(actions.userSetResponse(response.data, "delete-group"));
  } catch (error) {
    yield put(actions.userSetResponse(error.response.data, "delete-group"));
  }
}


export function* getGroupUserDetail(action) {
  const groupId = action.id;
  const params = action.params;
  const url = ApiBase.getUrl() + `/v2/users/group/${groupId}`;

  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
      params
    });

    yield put(actions.setGroupUserDetail(response.data.data));
  } catch (error) { }
}

export function* updateGroupUser(action) {
  const data = action.data;
  const groupId = data.group_uuid
  const url = ApiBase.getUrl() + `/v2/users/group/update/${groupId}`;

  try {
    const response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.getGroupUserDetail(groupId));
    yield put(actions.userSetResponse(response.data, "update-group-user"));
  } catch (error) {
    yield put(actions.userSetResponse(error.response.data, "update-group-user"));
  }
}


export function* syncLDAP(action) {
  const url = ApiBase.getUrl() + "/ldap/sync";
  let params = action.params

  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
      params
    });
    yield put(actions.userSetResponse(response.data, "sync-ldap"));
  } catch (error) {
    yield put(actions.userSetResponse(error.response.data, "sync-ldap"));
  }
}

export function* getListUserCompany(action) {
  const token = localStorage.getItem('token');
  const  {company_uuid} = jwt.decode(token)

  let params = action.params
  const url = ApiBase.getUrl() + `/users/company/list-user/${company_uuid}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
      params
    });

    yield put(actions.setListUserCompany(response.data.data));
  } catch { }
}

export function* searchUserCompany(action) {
  const token = localStorage.getItem('token');
  const  {company_uuid} = jwt.decode(token)

  let params = {...action.params, company: company_uuid}
  const url = ApiBase.getUrl() + `/compliance-users/search`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
      params
    });
    yield put(actions.setSearchUserCompany(response.data.data, action.from));
  } catch { }
}

export function* addGroupLabel(action) {
  let params = {...action.params}
  const url = ApiBase.getUrl() + `/v2/users/group/label`;
  try {
    const response = yield axios.post(url, params, {
      headers: {
        access_token: localStorage.getItem("token"),
      }
    });
    yield put(actions.setStateGroupLabelResponse(response.data, action.from));
  } catch (error){
    yield put(actions.setStateGroupLabelResponse(error.response.data, action.from));
  }
}

export function* getGroupLabel(action) {

  const url = ApiBase.getUrl() + `/v2/users/group/label`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token"),
      }
    });
    yield put(actions.setStateGroupLabel(response.data.data, action.from));
  } catch { }
}

export function* userInviteUser(action) {
  const data = action.payload.params;
  const url = ApiBase.getUrl() + `/users/invite`;
  try {
    yield axios.post(url, data, {
      headers: {
        access_token: localStorage.getItem("token"),
      },
    });
    yield action.payload.onSuccess()
  } catch (err) {
    if (err.response) action.payload.onError(err.response.data.message)
  }
}