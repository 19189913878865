import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {SocketContext, socket} from 'context/socket';
import ReactGA from "react-ga4";

import './index.css';
import i18next from './i18n';
import {I18nextProvider} from 'react-i18next';
import * as serviceWorker from './serviceWorker';
import userReducer from './store/reducers/user';
import notifReducer from './store/reducers/notification';
import regFrameReducer from './store/reducers/regulatoryFramework';
import regFrameDetailReducer from './store/reducers/regframeDetail';
import settingReducer from './store/reducers/settings';
import userManagementReducer from './store/reducers/userManagement';
import customerManagementReducer from './store/reducers/customerManagement';
import bookmarkReducer from './store/reducers/bookmark';
import searchReducer from './store/reducers/search';
import regulationHierarchyReducer from './store/reducers/regulationHierarchy';
import preferencesReducer from './store/reducers/preferences';
import dashboardReducer from './store/reducers/dashboard';
import productReducer from './store/reducers/product';
import restoreBackup from './store/reducers/restoreBackup';
import complianceReducer from './store/reducers/complianceMonitor';
import DocumentsManagement from './store/reducers/documentManagement';
import StaticPages from './store/reducers/StaticPages';
import aboutApp from './store/reducers/aboutApp';
import loaderApp from './store/reducers/loaderApp';
import feedbackFormReducer from './store/reducers/feedbackForm';

import Loader from './components/Loader/Loader'

import {
  watchPreferences,
  watchCustomerManagement,
  watchUserManagement,
  watchUser,
  watchRegFrame,
  notification,
  settingNotification,
  settings,
  watchBookmark,
  watchDashboard,
  watchRegFrameDetail,
  watchRegulationHierarchy,
  watchSearch,
  watchProduct,
  watchRestore,
  watchCompliance,
  watchDocumentMangement,
  watchStaticPage,
  watchAboutApp,
  watchLoaderApp,
  feedbackForm,
} from './store/sagas';


import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "utils/msalAuthConfig";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

const rootReducer = combineReducers({
  user: userReducer,
  notification: notifReducer,
  regFrame: regFrameReducer,
  regFrameDetail: regFrameDetailReducer,
  setting: settingReducer,
  bookmark: bookmarkReducer,
  userManagement: userManagementReducer,
  customerManagement: customerManagementReducer,
  search: searchReducer,
  regulationHierarchy: regulationHierarchyReducer,
  preferences: preferencesReducer,
  dashboard: dashboardReducer,
  product: productReducer,
  restoreBackup: restoreBackup,
  compliance: complianceReducer,
  DocumentsManagement: DocumentsManagement,
  content: StaticPages,
  aboutApp: aboutApp,
  loaderApp: loaderApp,
  feedbackForm: feedbackFormReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
    rootReducer,
    applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchRegFrame);
sagaMiddleware.run(notification);
sagaMiddleware.run(settingNotification);
sagaMiddleware.run(settings);
sagaMiddleware.run(watchRegFrameDetail);
sagaMiddleware.run(watchBookmark);
sagaMiddleware.run(watchUserManagement);
sagaMiddleware.run(watchCustomerManagement);
sagaMiddleware.run(watchSearch);
sagaMiddleware.run(watchRegulationHierarchy);
sagaMiddleware.run(watchPreferences);
sagaMiddleware.run(watchDashboard);
sagaMiddleware.run(watchProduct);
sagaMiddleware.run(watchRestore);
sagaMiddleware.run(watchCompliance);
sagaMiddleware.run(watchDocumentMangement);
sagaMiddleware.run(watchStaticPage);
sagaMiddleware.run(watchAboutApp);
sagaMiddleware.run(watchLoaderApp);
sagaMiddleware.run(feedbackForm);

const msalInstance = new PublicClientApplication(msalConfig);

const App = React.lazy(() => import('./App'));

if ( window._app_config.REACT_APP_GA_MEASUREMENT_ID ) {
  ReactGA.initialize(window._app_config.REACT_APP_GA_MEASUREMENT_ID);
  ReactGA.send("pageview");
  console.log('window._app_config.REACT_APP_GA_MEASUREMENT_ID',window._app_config.REACT_APP_GA_MEASUREMENT_ID)
}

const app = (
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <Suspense fallback={<Loader/>}>
              <MsalProvider instance={msalInstance}>
                <App />
              </MsalProvider>
            </Suspense>
          </BrowserRouter>
        </I18nextProvider>
      </Provider>
    </SocketContext.Provider>
);

if( window._app_config.REACT_APP_REDIRECT_HTTPS.toLowerCase() === 'true' ) {
  if ( window.location.protocol !== 'https:' ) { window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`); }
}

Sentry.init({
  dsn: window._app_config.REACT_APP_SENTRY_DSN,
  environment: window._app_config.REACT_APP_ENV,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,
});

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
