export const createSector = (data) => {
  return {
    type: "CREATE_SECTOR",
    data
  }
}

export const updateSector = (data) => {
  return {
    type: "UPDATE_SECTOR",
    data
  }
}

export const deleteSector = (data) => {
  return {
    type: "DELETE_SECTOR",
    data
  }
}

export const sectorResponse = (response) => {
  return {
    type: "SECTOR_RESPONSE",
    response
  }
}

export const sectorDeleteResponse = () => {
  return {
    type: "SECTOR_DELETE_RESPONSE",
  }
}