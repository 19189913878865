import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from '../../ApiBase';
import * as actions from '../../actions'

export function* getListStaticPage(action) {
  const url = ApiBase.getUrl() + '/content/list';
  let params = action.params
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem('token') },
      params
    });
    yield put(actions.setListStaticPage(response.data.data));
  } catch (error) {}
}

export function* getDetailStaticPage(action) {
  const params = action.params
  const url = ApiBase.getUrl()+`/content/detail`;
  try{
    const response = yield axios.get(
      url,
      {
        headers:{
          'access_token': localStorage.getItem("token")
        },
        params
      })
    const data = response.data.data
    yield put(actions.setDetailStaticPage(data))
  } catch (error){
    console.log(error.response)
  }
}

export function* createStaticPage(action) {
  const url = ApiBase.getUrl() + "/content/add";
  let data = action.data;
  try {
    let response =  yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.setResponseStaticPage(response.data, 'create_content'));
  } catch (error) {
    yield put(actions.setResponseStaticPage(error.response.data, "create_content"));
  }
}

export function* updateStaticPage(action) {
  const url = ApiBase.getUrl() + `/content/update/${action.id}`;
  let data = action.data;
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.setResponseStaticPage(response.data, "update_content"));
  } catch (error) {
    yield put(actions.setResponseStaticPage(error.response.data, "update_content"));
  }
}

export function* deleteStaticPage(action) {
  const url = ApiBase.getUrl() + `/content/delete/${action.id}`;
  try {
    const response = yield axios.delete(url, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.setResponseStaticPage(response.data, "delete_content"));
    yield put(actions.getListStaticPage());
  } catch (error) {
    yield put(actions.setResponseStaticPage(error.response.data, "delete_content"));
  }
}
