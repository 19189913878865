import { updateState } from "../../utils/updateState.js";

const initialState = {
  listStaticPage: null,
  detailStaticPage: null,
  staticPageResponse: null,
  staticPageResponseOrigin: null
}

const setListStaticPage = (state, action) => {
  return updateState(state, { listStaticPage: action.payload })
}

const setDetailStaticPage  = (state, action) => {
  return updateState(state, { detailStaticPage: action.payload })
}

const setResponseStaticPage  = (state, action) => {
  return updateState(state, { 
    staticPageResponse: action.response,
    staticPageResponseOrigin: action.responseOrigin
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LIST_STATIC_PAGE": return setListStaticPage(state, action);
    case "SET_DETAIL_STATIC_PAGE": return setDetailStaticPage(state, action);
    case "SET_RESPONSE_STATIC_PAGE": return setResponseStaticPage(state, action);
    default:
      return state;
  }
}

export default reducer;