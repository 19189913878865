import { updateState } from '../../utils/updateState.js';

const initialState = {
  productList: null
};

const setProductList = (state, action) => {
  return updateState(state, {
    productList: action.data
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRODUCT_LIST': return setProductList(state, action);
    default:
      return state;
  }
};

export default reducer;
