export const login = (email, password, params) => {
  return {
    type: 'USER_LOGIN',
    email,
    password,
    params
  };
};

export const loginSuccess = token => {
  return {
    type: 'USER_LOGIN_SUCCESS',
    token
  };
};

export const loginFailed = response => {
  return {
    type: 'USER_LOGIN_FAILED',
    response
  };
};

export const checkAuth = token => {
  return {
    type: 'USER_CHECK_AUTH',
    token
  };
};

export const getUserData = () => {
  return {
    type: 'USER_GET_DATA'
  };
};

export const getUserDataSuccess = (user_uuid, email, roles, iat, company_uuid, modules) => {
  return {
    type: 'USER_GET_DATA_SUCCESS',
    user_uuid,
    email,
    roles,
    iat,
    company_uuid, modules
  };
};

export const logout = () => {
  return {
    type: 'USER_LOGOUT'
  };
};

export const updateDataUser = (data) => {
  return {
    type: 'USER_UPDATE_DATA',
    data
  };
};

export const setUserResponse = (payload, actionType) => {
  return {
    type: 'USER_SET_RESPONSE',
    payload, actionType
  };
};


export const getUserProfile = (data) => {
  return {
    type: 'GET_USER_PROFILE',
    data
  };
};

export const setUserProfile = (payload) => {
  return {
    type: 'SET_USER_PROFILE',
    payload
  };
};

export const getUserDetail = (id) => {
  return {
    type: 'GET_USER_DETAIL',
    id
  };
};

export const setUserDetail = (payload) => {
  return {
    type: 'SET_USER_DETAIL',
    payload
  };
};

// role

export const getListRole = (data) => {
  return {
    type: 'GET_LIST_ROLE',
    data
  };
};

export const setListRole = (payload) => {
  return {
    type: 'SET_LIST_ROLE',
    payload
  };
};

export const getDetailRole = (id) => {
  return {
    type: 'GET_DETAIL_ROLE',
    id
  };
};

export const setDetailRole = (payload) => {
  return {
    type: 'SET_DETAIL_ROLE',
    payload
  };
};

export const updateUserById = (id, data) => {
  return {
    type: 'USER_UPDATE_BY_ID',
    id, data
  };
};

export const deleteUserById = (id) => {
  return {
    type: 'DELETE_USER_ID',
    id
  };
};

export const deleteUserByIdResponse = (payload, actionType) => {
  return {
    type: 'DELETE_USER_ID_RESPONSE',
    payload, actionType
  };
};

export const updateLicenseSubscription = (data) => {
  return {
    type: 'UPDATE_LICENSE_SUBSCRIPTION',
    data
  }
}

export const userCreatePassword = (payload) => {
  return {
    type: 'USER_CREATE_PASSWORD',
    payload
  }
}

export const checkInviteUserToken = (payload) => {
  return {
    type: 'CHECK_INVITE_USER_TOKEN',
    payload
  }
}