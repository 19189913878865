export const getListStaticPage = (params) => {
  return {
    type: "GET_LIST_STATIC_PAGE",
    params
  }
}

export const setListStaticPage = (payload) => {
  return {
    type: "SET_LIST_STATIC_PAGE",
    payload
  }
}

export const createStaticPage = (data) => {
  return {
    type: "CREATE_STATIC_PAGE",
    data
  }
}

export const getDetailStaticPage = (params) => {
  return {
    type: "GET_DETAIL_STATIC_PAGE",
    params
  }
}

export const setDetailStaticPage = (payload) => {
  return {
    type: "SET_DETAIL_STATIC_PAGE",
    payload
  }
}

export const deleteStaticPage = (id) => {
  return {
    type: "DELETE_STATIC_PAGE",
    id
  }
}

export const updateStaticPage = (id, data) => {
  return {
    type: "UPDATE_STATIC_PAGE",
    id, data
  }
}

export const setResponseStaticPage = (response, responseOrigin) => {
  return {
    type: "SET_RESPONSE_STATIC_PAGE",
    response, responseOrigin
  }
}