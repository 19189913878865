import { updateState } from '../../utils/updateState.js';

const initialState = {
  token: null,
  user_uuid: null,
  email: null,
  roles: null,
  iat: null,
  company_uuid: null,
  error_message: null,
  errors: null,
  attempts_message: null,
  authResponse: null,
  userResponse: null,
  userResponseType: null,
  profile: null,
  modules: null,
  privilege: null,
  listRoles: null,
  detailRole: null,
  userDetail: null
};

const loginSuccess = (state, action) => {
  return updateState(state, {
    token: action.token
  });
};

const loginFailed = (state, action) => {
  return updateState(state, {
    error_message: action.response.message,
    errors: action.response.errors ? action.response.errors : { password: action.response.message },
    attempts_message: action.response.attempts_message
  });
};

const getUserData = (state, action) => {
  return updateState(state, {
    user_uuid: action.user_uuid,
    email: action.email,
    iat: action.iat,
    company_uuid: action.company_uuid
  });
};

const setAuthResponse = (state, action) => {
  return updateState(state, { authResponse: action.payload });
};

const setUserResponse = (state, action) => {
  return updateState(state, {
    userResponse: action.payload,
    userResponseType: action.actionType
  });
};

const setUserProfile = (state, action) => {
  return updateState(state, {
    profile: action.payload,
    modules: action.payload.modules,
    roles: action.payload.roles && [action.payload.roles.role_name]
  });
};

const setListRole = (state, action) => {
  return updateState(state, {
    listRoles: action.payload,
  });
};

const setDetailRole = (state, action) => {
  return updateState(state, {
    detailRole: action.payload,
  });
};

const setUserDetail = (state, action) => {
  return updateState(state, {
    userDetail: action.payload,
  });
};

const deleteUserByIdResponse = (state, action) => {
  return updateState(state, {
    userResponse: action.payload,
    userResponseType: action.actionType
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGIN_SUCCESS':
      return loginSuccess(state, action);
    case 'USER_LOGIN_FAILED':
      return loginFailed(state, action);
    case 'USER_GET_DATA_SUCCESS':
      return getUserData(state, action);
    case 'SET_AUTH_RESPONSE':
      return setAuthResponse(state, action);
    case 'USER_SET_RESPONSE':
      return setUserResponse(state, action);
    case 'SET_USER_PROFILE':
      return setUserProfile(state, action);
    case 'SET_LIST_ROLE':
      return setListRole(state, action);
    case 'SET_DETAIL_ROLE':
      return setDetailRole(state, action);
    case 'SET_USER_DETAIL':
      return setUserDetail(state, action);
    case 'DELETE_USER_ID_RESPONSE':
      return deleteUserByIdResponse(state, action);
    default:
      return state;
  }
};

export default reducer;
