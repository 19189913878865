export const getProductList = (params) => {
  return {
    type: "GET_PRODUCT_LIST",
    params
  }
}

export const setProductList = (data) => {
  return {
    type: "SET_PRODUCT_LIST",
    data
  }
}