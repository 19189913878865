import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from "../ApiBase";
import * as actions from "../actions";

export function* getBookmarkObligation() {
  const url = ApiBase.getUrl() + "/obligation/list?is_bookmark=1";
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") },
    });

    yield put(actions.getBookmarkObligationResponse(response.data.data));
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* getBookmarkRegulation() {
  const url = ApiBase.getUrl() + "/regulations/list?is_bookmark=1";
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") },
    });

    yield put(actions.getBookmarkRegulationResponse(response.data.data));
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* getBookmarkDocument() {
  const url = ApiBase.getUrl() + "/documents/list?is_bookmark=1";
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") },
    });

    yield put(actions.getBookmarkDocumentResponse(response.data.data));
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* deleteBookmarkObligation(action) {
  const url = ApiBase.getUrl() + "/obligation/bookmark";
  try {
    yield axios.post(
      url,
      { obligation_uuid: action.obligationId },
      { headers: { access_token: localStorage.getItem("token") } }
    );
    yield put(actions.getBookmarkObligation());
  } catch (error) {
    actions.getBookmarkObligationResponse(error.response.data);
  }
}

export function* deleteBookmarkRegulation(action) {
  const url = ApiBase.getUrl() + "/regulations/bookmark";
  try {
    yield axios.post(
      url,
      { regulation_guid: action.regulationId },
      { headers: { access_token: localStorage.getItem("token") } }
    );
    yield put(actions.getBookmarkRegulation());
  } catch (error) {
    actions.getBookmarkRegulationResponse(error.response.data);
  }
}

export function* deleteBookmarkDocument(action) {
  const url = ApiBase.getUrl() + "/documents/bookmark";
  try {
    yield axios.post(
      url,
      { document_uuid: action.documentId },
      { headers: { access_token: localStorage.getItem("token") } }
    );
    yield put(actions.getBookmarkDocument());
  } catch (error) {
    actions.getBookmarkDocumentResponse(error.response.data);
  }
}

export function* getBookmarkCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/list?is_bookmark=1";
  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") },
    });

    yield put(actions.getBookmarkComplianceResponse(response.data.data));
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* deleteBookmarkCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/bookmark";
  try {
    yield axios.post(
      url,
      { compliance_uuid: action.id },
      { headers: { access_token: localStorage.getItem("token") } }
    );
    yield put(actions.getBookmarkCompliance());
  } catch (error) {
    actions.getBookmarkComplianceResponse(error.response.data);
  }
}
