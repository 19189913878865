import { updateState } from '../../utils/updateState.js';

const initialState = {
    loadingFeedbackForm: false,
    successFeedbackForm: false,
    errorMessageFeedbackForm: null,
    sidebarShown: false,
};

const setLoadingFeedbackForm = (state, action) => {
    return updateState(state, {
        loadingFeedbackForm: action.payload
    })
}

const setSuccessFeedbackForm = (state, action) => {
    return updateState(state, {
        successFeedbackForm: action.payload
    })
}

const setErrorMessageFeedbackForm = (state, action) => {
    return updateState(state, {
        errorMessageFeedbackForm: action.payload
    })
}

const setSidebarShown = (state, action) => {
    return updateState(state, {
        sidebarShown: action.payload
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LOADING_FEEDBACK_FORM': return setLoadingFeedbackForm(state, action);
        case 'SET_SUCCESS_FEEDBACK_FORM': return setSuccessFeedbackForm(state, action);
        case 'SET_ERROR_MESSAGE_FEEDBACK_FORM': return setErrorMessageFeedbackForm(state, action);
        case 'SET_SIDEBAR_SHOWN': return setSidebarShown(state, action)
        default:
            return state;
    }
};

export default reducer;
