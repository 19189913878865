export const getAlertList = (params) => {
  return {
    type: "GET_ALERT_LIST",
    params
  }
}

export const setAlertList = (data) => {
  return {
    type: "SET_ALERT_LIST",
    data
  }
}

export const updateAlertList = (data) => {
  return {
    type: "UPDATE_ALERT_LIST",
    data
  }
}

export const alertListResponse = (response) => {
  return {
    type: "ALERT_LIST_RESPONSE",
    response
  }
}

export const syncAlert = () => {
  return {
    type: "SYNC_ALERT"
  }
}

export const downloadAlertTemplate = (params) => {
  return {
    type: "DOWNLOAD_ALERT_TEMPLATE",
    params
  }
}

export const setAlertTemplate = (payload) => {
  return {
    type: "SET_ALERT_TEMPLATE",
    payload
  }
}

export const setSettingResponse = (payload, origin) => {
  return {
    type: "SET_SETTING_RESPONSE",
    payload, origin
  }
}