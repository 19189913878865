export const setSearchSuggestions = (payload) => {
  return {
    type: "SET_SEARCH_SUGGESTIONS",
    payload
  }
};

export const setSearchKeyword = (payload) => {
  return {
    type: 'SET_SEARCH_KEYWORD',
    payload
  }
};

export const setSearchIsLoading = (payload) => {
  return {
    type: 'SET_SEARCH_LOADING',
    payload
  }
};

export const setSearchCategory = (payload) => {
  return {
    type: 'SET_SEARCH_CATEGORY',
    payload
  }
};

export const getSearchSuggestions = (payload) => {
  return {
    type: 'GET_SEARCH_SUGGESTION',
    payload
  }
};

export const getSearchResult = (payload) => {
  return {
    type: 'GET_SEARCH_RESULT',
    payload
  }
};

export const setSearchResult = (payload, origin) => {
  return {
    type: 'SET_SEARCH_RESULT',
    payload, origin
  }
}

export const getSearchElastic = (data) => {
  return {
    type: "GET_SEARCH_ELASTIC",
    data
  }
}