export const getRegulationHierarchy = (params) => {
  return {
    type: "GET_REGULATION_HIERARCHY",
    params
  }
}

export const setRegulationHierarchySuccess = (data, page_total) => {
  return {
    type: "SET_REGULATION_HIERARCHY_SUCCESS",
    data,
    page_total
  }
}

export const setRegulationHierarchyResponse = (data) => {
  return {
    type: "SET_REGULATION_HIERARCHY_RESPONSE",
    data
  }
}

export const addRegulationHierarchy = (data) => {
  return {
    type: "ADD_REGULATION_HIERARCHY",
    data
  }
}

export const updateRegulationHierarchy = (data) => {
  return {
    type: "UPDATE_REGULATION_HIERARCHY",
    data
  }
}

export const deleteRegulationHierarchy = (regId) => {
  return {
    type: "DELETE_REGULATION_HIERARCHY",
    regId
  }
}

export const setResponseRegulation = (response, responseOrigin) => {
  return { type: "SET_RESPONSE_REGULATION", response, responseOrigin }
}

export const syncRegulationHierarchy = (params) => {
  return { type: "SYNC_REGULATION_HIERARCHY", params }
}