class ApiBase {

  static API_URL = window._app_config.REACT_APP_API_URL
  static API_MOCK = window._app_config.REACT_APP_API_URL_MOCK

  static ELASTIC_URL = window._app_config.REACT_APP_ELASTIC_URL
  static ELASTIC_MOCK = window._app_config.REACT_APP_ELASTIC_URL_MOCK

  static PDF_URL = window._app_config.REACT_APP_ROOT_URL
  static PDF_MOCK = window._app_config.REACT_APP_ROOT_URL_MOCK

  static getUrl() {
    switch (window._app_config.REACT_APP_ENV) {
      case 'mock':
        return this.API_MOCK
      case 'production':
        return this.API_URL
      default:
        return this.API_URL
    }
  }

  static getElasticUrl() {
    switch (window._app_config.REACT_APP_ENV) {
      case 'mock':
        return this.ELASTIC_MOCK
      case 'production':
        return this.ELASTIC_URL
      default:
        return this.ELASTIC_URL
    }
  }

  static getPdfUrl() {
    switch (window._app_config.REACT_APP_ENV) {
      case 'mock':
        return this.PDF_URL_MOCK
      case 'production':
        return this.PDF_URL
      default:
        return this.PDF_URL
    }
  }
}

export default ApiBase;
