import {updateState} from '../../utils/updateState.js';

const initialState = {
  regulationHierarchy: [],
  pageTotal: 1,
  response: null,
  regulationResponse: null,
  regulationResponseOrigin: null
};

const setRegulationHierarchySuccess = (state, action) => {
  return updateState(state, {
    regulationHierarchy: action.data,
    pageTotal: action.data.total_page
  });
};

const setRegulationHierarchyResponse = (state, action) => {
  return updateState(state, {
    regulationHierarchy: action.data,
  });
};

const setRegulationResponse = (state, action) => {
  return updateState(state,
    {
      regulationResponse: action.response,
      regulationResponseOrigin: action.responseOrigin,
    }
  )
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_REGULATION_HIERARCHY_SUCCESS':
      return setRegulationHierarchySuccess(state, action);
    case 'SET_REGULATION_HIERARCHY_RESPONSE':
      return setRegulationHierarchyResponse(state, action);
    case 'SET_RESPONSE_REGULATION':
      return setRegulationResponse(state, action)
    default:
      return state;
  }
};

export default reducer;
