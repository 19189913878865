export const getNotification = (params) => {
  return {
    type: "GET_NOTIFICATION",
    params
  }
}

export const setNotification = (data) => {
  return {
    type: "SET_NOTIFICATION",
    data
  }
}

export const setNotificationHeader = (data) => {
  return {
    type: "SET_NOTIFICATION_HEADER",
    data
  }
}

export const getCounterStaticNotification = () => {
  return {
    type: "GET_COUNTER_STATIC_NOTIFICATION",
  }
}

export const setCounterStaticNotification = (data) => {
  return {
    type: "SET_COUNTER_STATIC_NOTIFICATION",
    data
  }
}

export const getDetailNotification = (id, params) => {
   return {
     type: "GET_DETAIL_NOTIFICATION",
     id, params
   }
}

export const setDetailNotification = (data) => {
  return {
    type: "SET_DETAIL_NOTIFICATION",
    data
  }
}