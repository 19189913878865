import React from 'react';
import { Spinner } from 'react-bootstrap';

import './Loader.scss';

const Loader = () => (
  <div className="wrapper-spinner">
    <div className="loader">
      <Spinner animation="border" />
    </div>
  </div>
);

export default Loader;
