import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from '../../ApiBase';
import * as actions from '../../actions'

export function* recoveryPassword(action) {
  let url = ApiBase.getUrl()+'/auth/recovery-password?lang='+action.params.lang;

  try{
    let response = yield axios.post(url, {email: action.email})
    yield put(actions.setAuthResponse(response.data))
  } catch(error){
    yield put(actions.setAuthResponse(error.response.data))
  }
}

export function* submitPasswordRecovery(action) {
  const url = ApiBase.getUrl()+'/auth/recovery-password/change-password';
  let data = action.data
  try{
    let response =  yield axios.post(url, data)
    yield put(actions.setAuthResponse(response.data))
  } catch(error){
    yield put(actions.setAuthResponse(error.response.data))
  }
}