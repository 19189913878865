export const getObligationList = (params) => {
  return {
    type: "GET_OBLIGATION_LIST",
    params
  }
}
export const setObligationList = (payload) => {
  return {
    type: "SET_OBLIGATION_LIST",
    payload
  }
}
export const setObligationDetailLoading = (payload) => {
  return {
    type: "SET_OBLIGATION_DETAIL_LOADING",
    payload
  }
}

export const getObligationDetail = (id) => {
  return {
    type: "GET_OBLIGATION_DETAIL",
    id
  }
}

export const setObligationDetail = (payload) => {
  return {
    type: "SET_OBLIGATION_DETAIL",
    payload
  }
}

export const saveObligation = (data, actionType, sectorId) => {
  return {
    type: "SAVE_OBLIGATION_DETAIL",
    data, actionType, sectorId
  }
}

export const setObligationResponse = (payload, resOrigin) => {
  return {
    type: "SET_OBLIGATION_RESPONSE",
    payload, resOrigin
  }
}

export const getObligationType = () => {
  return {
    type: "GET_OBLIGATION_TYPE"
  }
}

export const setObligationType = (data) => {
  return {
    type: "SET_OBLIGATION_TYPE",
    data
  }
}

export const bookmarkObligation = (id) => {
  return {
    type: "BOOKMARK_OBLIGATION",
    id
  }
}

export const createObligation = (data, sectorId) => {
  return {
    type: "CREATE_OBLIGATION",
    data, sectorId
  }
}

export const getListUpdateObligation = (params) => {
  return {
    type: "GET_LIST_UPDATE_OBLIGATION",
    params
  }
}

export const getListUpdateObligationByRegulation = (params) => {
  return {
    type: "GET_LIST_UPDATE_OBLIGATION_BY_REGULATION",
    params
  }
}

export const setListUpdateObligation = (payload) => {
  return {
    type: "SET_LIST_UPDATE_OBLIGATION",
    payload
  }
}

export const setListUpdateObligationByRegulation = (payload) => {
  return {
    type: "SET_LIST_UPDATE_OBLIGATION_BY_REGULATION",
    payload
  }
}

export const getDetailUpdateObligation = (params) => {
  return {
    type: "GET_DETAIL_UPDATE_OBLIGATION",
    params
  }
}

export const setDetailUpdateObligation = (payload) => {
  return {
    type: "SET_DETAIL_UPDATE_OBLIGATION",
    payload
  }
}

export const applyObligationChanges = (data) => {
  return {
    type: "APPLY_OBLIGATION_CHANGES",
    data
  }
}

export const getObligationUpdatingContentList = () => {
  return {
    type: "GET_OBLIGATION_UPDATING_CONTENT_LIST"
  }
}

export const setObligationUpdatingContentList = (payload) => {
  return {
    type: "SET_OBLIGATION_UPDATING_CONTENT_LIST",
    payload
  }
}
