import { updateState } from "../../utils/updateState.js";

const initialState = {
  sectors: null,
  sectorDetail: null,
  templateUrl: null,
  uploadResponse: null,
  sectorResponse: null,
  obligationList: null,
  obligationListUpdate: null,
  obligationListUpdateByRegulation: null,
  obligationDetailUpdate: null,
  obligationDetail: null,
  obligationResponse: null,
  obligationResponseOrigin: null,
  obligationType: null,
  regulations: null,
  regulationCentral: null,
  regulationDetail: null,
  deleteResponse: null,
  regulationResponse: null,
  regulationResponseType: null,
  regulationList: null,
  pageTotal: 1,
  unpublishedContent: null,
  sectorsTopicsTree: [],
  sectorDetailLoading: null,
  obligationDetailLoading: null,
  obligationUpdatingContentList: [],
  lastSyncClearCache: null,
  regulationDetailLoading: null,
}

const putSectorList = (state, action) => {
  return updateState(state, {
    sectors: action.sectors,
    pageTotal: action.pageTotal,
    unpublishedContent: action.unpublishedContent,
  })
};

const putLatestSyncClearCache = (state, action) => {
  return updateState(state, {
    lastSyncClearCache: action.payload,
  })
};

const putSectorDetail = (state, action) => {
  return updateState(state, {
    sectorDetail: action.sector
  })
}

const putSectorsTopicsTree = (state, action) => {
  return updateState(state, {
    sectorsTopicsTree: action.data
  })
}

const uploadResponse = (state, action) => {
  return updateState(state, {
    uploadResponse: action.payload
  })
}

const deleteUploadResponse = (state) => {
  return updateState(state, {
    uploadResponse: null
  }) 
}


const putTemplateUrl = (state, action) => {
  return updateState(state, {
    templateUrl: action.fileUrl
  })
}

const setRespCreateSector = (state, action) => {
  return updateState(state, {
    sectorResponse: action.response
  })
}

const deleteRespSector = (state) => {
  return updateState(state, {
    sectorResponse: null
  }) 
}

const setRegulation = (state, action) => {
  return updateState(state, {
    regulations: action.payload
  })
}

const setRegulationCentral = (state, action) => {
  return updateState(state, {
    regulationCentral: action.payload
  })
}
const setRegulationDetail = (state, action) => {
  return updateState(state, {
    regulationDetail: action.payload
  })
}

const setObligationList = (state, action) => {
  return updateState(state, {
    obligationList: action.payload
  })
}

const setObligationListUpdate = (state, action) => {
  return updateState(state, {
    obligationListUpdate: action.payload
  })
}

const setObligationListUpdateByRegulation = (state, action) => {
  return updateState(state, {
    obligationListUpdateByRegulation: action.payload
  })
}

const setObligationDetailUpdate = (state, action) => {
  return updateState(state, {
    obligationDetailUpdate: action.payload
  })
}

const setObligationDetail = (state, action) => {
  return updateState(state, {
    obligationDetail: action.payload
  })
}

const setObligationResponse = (state, action) => {
  return updateState(state, {
    obligationResponse: action.payload,
    obligationResponseOrigin: action.resOrigin
  })
}

const setObligationType = (state, action) => {
  return updateState(state, {
    obligationType: action.data
  })
}

const setDeleteTopicResponse = (state, action) => {
  return updateState(state, {
    deleteResponse: action.payload
  })
}

const setRegulationResponse = (state, action) => {
  return updateState(state, {
    regulationResponse: action.payload,
    regulationResponseType: action.actionType
  })
}


const setDataRegulationList = (state, action) => {
  return updateState(state, {
    regulationList: action.data
  })
}

const setSectorsDetailLoading = (state, action) => {
  return updateState(state, {
    sectorDetailLoading: action.payload
  })
}

const setObligationDetailLoading = (state, action) => {
  return updateState(state, {
    obligationDetailLoading: action.payload
  })
}

const setObligationUpdatingContentList = (state, action) => {
  return updateState(state, {
    obligationUpdatingContentList: action.payload
  }) 
}

const setRegulationDetailLoading = (state, action) => {
  return updateState(state, {
    regulationDetailLoading: action.payload
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SECTOR_LIST": return putSectorList(state, action);
    case "SET_SECTOR_DETAIL": return putSectorDetail(state, action);
    case "SET_SECTORS_TOPICS_TREE": return putSectorsTopicsTree(state, action);
    case "SET_XLS_TEMPLATE": return putTemplateUrl(state, action);
    case "SET_UPLOAD_RESPONSE": return uploadResponse(state, action);
    case "DELETE_UPLOAD_RESPONSE": return deleteUploadResponse(state, action);
    case "SECTOR_RESPONSE": return setRespCreateSector(state, action);
    case "SECTOR_DELETE_RESPONSE": return deleteRespSector(state, action);
    case "SET_REGULATION": return setRegulation(state, action);
    case "SET_REGULATION_CENTRAL": return setRegulationCentral(state, action);
    case "SET_REGULATION_DETAIL": return setRegulationDetail(state, action);
    case "SET_OBLIGATION_LIST": return setObligationList(state, action);
    case "SET_LIST_UPDATE_OBLIGATION": return setObligationListUpdate(state, action);
    case "SET_LIST_UPDATE_OBLIGATION_BY_REGULATION": return setObligationListUpdateByRegulation(state, action);
    case "SET_DETAIL_UPDATE_OBLIGATION": return setObligationDetailUpdate(state, action);
    case "SET_OBLIGATION_DETAIL": return setObligationDetail(state, action);
    case "SET_OBLIGATION_RESPONSE": return setObligationResponse(state, action);
    case "SET_OBLIGATION_TYPE": return setObligationType(state, action);
    case "SET_DELETE_TOPIC_RESPONSE": return setDeleteTopicResponse(state, action);
    case "SET_REGULATION_RESPONSE": return setRegulationResponse(state, action);
    case "SET_REGULATION_LIST": return setDataRegulationList(state, action);
    case "SET_SECTORS_DETAIL_LOADING": return setSectorsDetailLoading(state, action);
    case "SET_OBLIGATION_DETAIL_LOADING": return setObligationDetailLoading(state, action);
    case "SET_OBLIGATION_UPDATING_CONTENT_LIST": return setObligationUpdatingContentList(state, action);
    case "SET_REGULATION_DETAIL_LOADING": return setRegulationDetailLoading(state, action);
    case "SET_LATEST_SYNC_CLEAR_CACHE": return putLatestSyncClearCache(state, action);
    default:
      return state;
  }
}

export default reducer;