export const userGetUserList = (q, page, sortBy, sortValue) => {
  return {
    type: "USER_MANAGEMENT_GET_USER_LIST",
    q, page, sortBy, sortValue
  };
};

export const userGetUserGroupList = (q, page, sortBy, sortValue) => {
  return {
    type: "USER_MANAGEMENT_GET_USER_GROUP_LIST",
    q, page, sortBy, sortValue
  };
};

export const userGetUserListSuccess = (userList) => {
  return {
    type: "USER_MANAGEMENT_GET_USER_LIST_SUCCESS",
    userList,
  };
};

export const userSetUserListParams = (params) => {
  return {
    type: "USER_MANAGEMENT_SET_USER_LIST_PARAMS",
    params,
  };
};

export const userGetUserGroupListSuccess = (userList) => {
  return {
    type: "USER_MANAGEMENT_GET_USER_GROUP_LIST_SUCCESS",
    userList,
  };
};

export const userCreateUser = (data) => {
  return {
    type: "USER_MANAGEMENT_CREATE_USER",
    data
  };
};

export const userSetResponse = (response, responseType) => {
  return {
    type: "USER_MANAGEMENT_SET_RESPONSE",
    response, responseType
  };
};

export const userDeleteResponse = () => {
  return {
    type: "USER_MANAGEMENT_DELETE_RESPONSE",
  };
};

export const userGetRolePrivilege = () => {
  return {
    type: "USER_MANAGEMENT_GET_ROLE_PRIVILEGE",
  };
};

export const userSetRolePrivilege = (privilegeRoleList) => {
  return {
    type: "USER_MANAGEMENT_SET_ROLE_PRIVILEGE",
    privilegeRoleList,
  };
};

export const userAddRolePrivilege = (name) => {
  return {
    type: "USER_MANAGEMENT_ADD_ROLE_PRIVILEGE",
    name,
  };
};

export const userAddRolePrivilegeResponse = (response) => {
  return {
    type: "USER_MANAGEMENT_ADD_ROLE_PRIVILEGE_RESPONSE",
    response,
  };
};

export const userGetModulePrivilege = () => {
  return {
    type: "USER_MANAGEMENT_GET_MODULE_PRIVILEGE",
  };
};

export const userGetModulePrivilegeResponse = (privilegeModuleList) => {
  return {
    type: "USER_MANAGEMENT_GET_MODULE_PRIVILEGE_RESPONSE",
    privilegeModuleList,
  };
};

export const userDeleteRolePrivilege = (role_id) => {
  return {
    type: "USER_MANAGEMENT_DELETE_ROLE_PRIVILEGE",
    role_id,
  };
};

export const userDeleteRolePrivilegeResponse = (response) => {
  return {
    type: "USER_MANAGEMENT_DELETE_ROLE_PRIVILEGE_RESPONSE",
    response,
  };
};

export const userUpdateRolePrivilege = (data) => {
  return {
    type: "USER_MANAGEMENT_UPDATE_ROLE_PRIVILEGE",
    data,
  };
};

export const userUpdateRolePrivilegeResponse = (response) => {
  return {
    type: "USER_MANAGEMENT_DELETE_ROLE_PRIVILEGE_RESPONSE",
    response,
  };
};

export const getGroupUserList = (q, page, sortBy, sortValue) => {
  return {
    type: "GET_GROUP_USER_LIST",
    q, page, sortBy, sortValue
  };
};

export const setGroupUserList = (response) => {
  return {
    type: "SET_GROUP_USER_LIST",
    response
  };
};

export const deleteGroupUser = (id) => {
  return {
    type: "DELETE_GROUP_USER",
    id
  };
};

export const getGroupUserDetail = (id, params) => {
  return {
    type: "GET_GROUP_USER_DETAIL",
    id, params
  };
};

export const setGroupUserDetail = (payload) => {
  return {
    type: "SET_GROUP_USER_DETAIL",
    payload
  };
};

export const createGroupUser = (data) => {
  return {
    type: "CREATE_GROUP_USER",
    data
  };
};

export const updateGroupUser = (data) => {
  return {
    type: "UPDATE_GROUP_USER",
    data
  };
};

export const syncLDAP = (params) => {
  return {
    type: "SYNC_LDAP", params
  };
};

export const getListUserCompany = (params) => {
  return {
    type: "GET_LIST_USER_COMPANY",
    params
  };
};

export const setListUserCompany = (payload) => {
  return {
    type: "SET_LIST_USER_COMPANY",
    payload
  };
};

export const searchUserCompany = (params, from) => {
  return {
    type: "SEARCH_USER_COMPANY",
    params, from
  };
};

export const setSearchUserCompany = (payload, from) => {
  return {
    type: "SET_SEARCH_USER_COMPANY",
    payload, from
  };
};


export const addGroupLabel = (params) => {
  return {
    type: "ADD_GROUP_LABEL",
    params
  };
};


export const getGroupLabel = (params) => {
  return {
    type: "GET_GROUP_LABEL",
    params
  };
};


export const setStateGroupLabel = (payload, from) => {
  return {
    type: "SET_GROUP_LABEL",
    payload, from
  };
};

export const setStateGroupLabelResponse = (payload, from) => {
  return {
    type: "SET_GROUP_LABEL_RESPONSE",
    payload, from
  };
};

export const userInviteUser = (payload) => {
  return {
    type: "USER_INVITE_USER",
    payload
  };
};

export const setInviteNewUserSuccess = (payload) => {
  return {
    type: "SET_INVITE_NEW_USER_SUCCESS",
    payload
  };
};

export const setDeletedUser = payload => {
  return {
    type: "SET_DELETED_USER",
    payload
  };
};

export const setDeletedUserGroup = payload => {
  return {
    type: "SET_DELETED_USER_GROUP",
    payload
  };
}
