import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from '../ApiBase';
import * as actions from '../actions'

export function* reqNotif(action) {
  let params = action.params
  const url = ApiBase.getUrl()+`/notification/list`;
  
  try{
    const response = yield axios.get(
      url, 
      {
        headers:{
          'access_token': localStorage.getItem("token")
        },
        params: params
      })
    const data = response.data.data

    if(params.notifHeader){
      yield put(actions.setNotificationHeader(data))
    }else{
      yield put(actions.setNotification(data))
    }
  }catch{}
}

export function* getCountStaticNotif(){
  const url = ApiBase.getUrl() + `/notification/counter-list`

  try {
    const response = yield axios.get(url, {
      headers:{ 'access_token': localStorage.getItem("token") }
    })

    yield put(actions.setCounterStaticNotification(response.data.data))
  } catch {}
}

export function* getDetailNotif(action){
  const id = action.id
  const params = action.params
  const pageOn = params.page
  const lang = params.lang

  const url = ApiBase.getUrl() + `/notification/${id}`

  try {
    const response = yield axios.get(url, {
      headers: {
        'access_token': localStorage.getItem("token")
      },
      params: {
        lang: lang
      }
    })

    yield put(actions.setDetailNotification(response.data.data))
    yield put(actions.getNotification({page: pageOn, limit: 5}))
  } catch (error) {
    yield put(actions.setDetailNotification(error.response.data))
  }
}