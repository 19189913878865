export const recoveryPassword = (email, params) => {
  return {
    type: 'RECOVERY_PASSWORD', email, params
  };
};

export const setAuthResponse = (payload) => {
  return {
    type: 'SET_AUTH_RESPONSE', payload
  };
};

export const submitPasswordRecovery = data => {
  return {
    type: 'SUBMIT_PASSWORD_RECOVERY', data
  };
};