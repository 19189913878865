export const customerGetCustomerList = (page, params) => {
  return {
    type: "CUSTOMER_MANAGEMENT_GET_CUSTOMER_LIST",
    page, params
  };
};

export const getCustomerDetail = (companyId) => {
  return {
    type: "GET_CUSTOMER_DETAIL",
    companyId,
  };
};

export const setCustomerDetail = (payload) => {
  return {
    type: "SET_CUSTOMER_DETAIL",
    payload,
  };
};

export const getCustomerActivity = (id) => {
  return {
    type: "GET_CUSTOMER_ACTIVITY",
    id,
  };
};

export const setCustomerActivity = (payload) => {
  return {
    type: "SET_CUSTOMER_ACTIVITY",
    payload,
  };
};

export const customerGetCustomerListSuccess = (customerList, pageTotal) => {
  return {
    type: "CUSTOMER_MANAGEMENT_GET_CUSTOMER_LIST_SUCCESS",
    customerList,
    pageTotal,
  };
};

export const customerCreateCustomer = (data) => {
  return {
    type: "CUSTOMER_MANAGEMENT_CREATE_CUSTOMER",
    data,
  };
};

export const customerUpdateCustomer = (data) => {
  return {
    type: "CUSTOMER_MANAGEMENT_UPDATE_CUSTOMER",
    data,
  };
};

export const customerDeleteCustomer = (companyId) => {
  return {
    type: "CUSTOMER_MANAGEMENT_DELETE_CUSTOMER",
    companyId,
  };
};

export const customerSetResponse = (response) => {
  return {
    type: "CUSTOMER_MANAGEMENT_SET_RESPONSE",
    response,
  };
};

export const customerDeleteResponse = () => {
  return {
    type: "CUSTOMER_MANAGEMENT_DELETE_RESPONSE",
  };
};

export const customerResponse = (response, origin) => {
  return {
    type: "RESPONSE_CUSTOMER_MANAGEMENT",
    response,
    origin,
  };
};

// export const generateCsv = (companyId) => {
//   return {
//     type: "GENERATE_CSV",
//     companyId
//   }
// }

export const uploadCsv = (files, companyId) => {
  return {
    type: "UPLOAD_CSV",
    files, companyId
  }
}

export const uploadInitData = (files) => {
  return {
    type: "UPLOAD_INIT_DATA",
    files
  }
}

export const updateCompanyProgress = (page) => {
  return {
    type: "UPDATE_COMPANY_PROGRESS",
    page
  }
}