import { updateState } from "../../utils/updateState.js";

const initialState = {
  complianceList: null,
  complianceListError: false,
  complianceDetail: null,
  itemFilterCompliance: null,
  loadingFilterCompliance: false,
  complianceDetailActions: null,
  complianceResponse: null,
  complianceResponseOrigin: null,
  complianceListTotalPage: 1,
  listRoleCompliance: null,
  listObligationAction: null,
  detailRoleCompliance: null,
  detailChecklistCompliance: null,
  listComment: null,
  activityList: null,
  activityListAll: null,
  pageTotal: 1,
  complianceTotalData: null,
  loadingConfirmCompliance: false,
  confirmComplianceResponse: null,
  complianceDetailChanges: null,
  complianceMainInfoChanges: null,
  complianceChecklistChanges: null,
  complianceRegulationChanges: null,
};

const setListCompliance = (state, action) => {
  return updateState(state, { 
    complianceList: action.payload.data,
    complianceListTotalPage: action.payload.total_page,
  });
};

const setComplianceListError = (state, action) => {
  return updateState(state, {
    complianceListError: action.payload,
  });
};

const setDetailCompliance = (state, action) => {
  return updateState(state, {
    complianceDetail: action.payload,
    complianceDetailActions: action.payload.actions
  });
};

const setResponseCompliance = (state, action) => {
  return updateState(state, {
    complianceResponse: action.response,
    complianceResponseOrigin: action.responseOrigin
  });
};

const setListRoleCompliance = (state, action) => {
  return updateState(state, {
    listRoleCompliance: action.payload
  });
};

const setListObligationAction = (state, action) => {
  return updateState(state, {
    listObligationAction: action.payload
  });
};

const setDetailRoleCompliance = (state, action) => {
  return updateState(state, {
    detailRoleCompliance: action.payload
  });
};

const setListMemberCompliance = (state, action) => {
  return updateState(state, {
    listMemberCompliance: action.payload
  });
};

const setDetailChecklistCompliance = (state, action) => {
  return updateState(state, {
    detailChecklistCompliance: action.payload
  });
};

const setListComment = (state, action) => {
  return updateState(state, {
    listComment: action.data
  });
};

const getActivityList = (state, action) => {
  return updateState(state, {
    activityList: action.data
  });
};

const getActivityListAllResponse = (state, action) => {
  return updateState(state, {
    activityListAll: action.data,
    pageTotal: action.page_total
  });
};

const setFilterCompliance = (state, action) => {
  return updateState(state, {
    itemFilterCompliance: action.payload
  });
};

const setComplianceTotalData = (state, action) => {
  return updateState(state, {
    complianceTotalData: action.payload
  });
};

const setLoadingConfirmCompliance = (state, action) => {
  return updateState(state, {
    loadingConfirmCompliance: action.payload
  });
};

const setConfirmComplianceResponse = (state, action) => {
  return updateState(state, {
    confirmComplianceResponse: action.payload
  });
};

const setComplianceDetailChanges = (state, action) => {
  return updateState(state, {
    complianceDetailChanges: action.payload
  });
};

const setComplianceMainInfoChanges = (state, action) => {
  return updateState(state, {
    complianceMainInfoChanges: action.payload
  });
};

const setComplianceChecklistChanges = (state, action) => {
  return updateState(state, {
    complianceChecklistChanges: action.payload
  });
};

const setComplianceRegulationChanges = (state, action) => {
  return updateState(state, {
    complianceRegulationChanges: action.payload
  });
};

const setLoadingFilterCompliance = (state, action) => {
  return updateState(state, {
    loadingFilterCompliance: action.payload
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LIST_COMPLIANCE":
      return setListCompliance(state, action);
    case "SET_DETAIL_COMPLIANCE":
      return setDetailCompliance(state, action);
    case "SET_RESPONSE_COMPLIANCE":
      return setResponseCompliance(state, action);
    case "SET_LIST_ROLE_COMPLIANCE":
      return setListRoleCompliance(state, action);
    case "SET_LIST_OBLIGATION_ACTION":
      return setListObligationAction(state, action);
    case "SET_DETAIL_ROLE_COMPLIANCE":
      return setDetailRoleCompliance(state, action);
    case "SET_LIST_MEMBER_COMPLIANCE":
      return setListMemberCompliance(state, action);
    case "SET_DETAIL_CHECKLIST_COMPLIANCE":
      return setDetailChecklistCompliance(state, action);
    case "SET_COMMENT":
      return setListComment(state, action);
    case "GET_ACTIVITY_LIST":
      return getActivityList(state, action);
    case "GET_ACTIVITY_LIST_ALL_RESPONSE":
      return getActivityListAllResponse(state, action);
    case "SET_FILTER_COMPLIANCE":
      return setFilterCompliance(state, action);
    case "SET_COMPLIANCE_TOTAL_DATA":
      return setComplianceTotalData(state, action);
    case "SET_LOADING_CONFIRM_COMPLIANCE":
      return setLoadingConfirmCompliance(state, action);
    case "SET_CONFIRM_COMPLIANCE_RESPONSE":
      return setConfirmComplianceResponse(state, action);
    case "SET_COMPLIANCE_DETAIL_CHANGES":
      return setComplianceDetailChanges(state, action);
    case "SET_COMPLIANCE_MAIN_INFO_CHANGES":
      return setComplianceMainInfoChanges(state, action);
    case "SET_COMPLIANCE_CHECKLIST_CHANGES":
      return setComplianceChecklistChanges(state, action);
    case "SET_COMPLIANCE_REGULATION_CHANGES":
      return setComplianceRegulationChanges(state, action);
    case "SET_LOADING_FILTER_COMPLIANCE":
      return setLoadingFilterCompliance(state, action);
    case "SET_COMPLIANCE_LIST_ERROR": 
      return setComplianceListError(state, action);
    default:
      return state;
  }
};

export default reducer;
